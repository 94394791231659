/*!

=========================================================
* user Registration - v1.1.0
=========================================================
* Copyright 2019 kanaa (http://www.kanaa.net)

@author: Maatharasi Kulandaivelu
@Since 27/11/2019
=========================================================



*/
import React, { useState, createContext } from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  UncontrolledAlert,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  Alert

} from "reactstrap";
//import Table from "../variables/table.jsx";
import {API_URL_AUTHENTICATE, ACCESS_ORGIN} from "./Constants";

import logo from "../ksquarelogo.png";

export const userContext = React.createContext();
class SignIn extends React.Component {
  constructor(props)
  {
    super(props);
    this.handleSubmit=this.handleSubmit.bind(this);
    this.handleChange=this.handleChange.bind(this);
	
				
    //this.handleClose=this.handleClose.bind(this);
    //this.onCancel=this.onCancel.bind(this);
   // this.state={result:[]};
    this.state={
                user:{username:"", password:""},
                errors:{username:"", password:""},
                resmsg:{success:"",error:""},
				showResult:false
                
				 }

    
     //let [modal, setModal]=useState(false);
  //let toggle = () => setModal(!modal);

  }



  handleChange(event)
  {

    let value = event.target.value;
    let name = event.target.name;
    this.setState(
      prevState => ({
        user: {
          ...prevState.user,
          [name]: value
        }
      }),
      () => console.log(this.state.user)
    );
  
  }
 handleClearForm(e) {
    e.preventDefault();
    this.setState({
     user: {
        username:"", password:""
      }
      
    });
  }
  handleSubmit(event) {
	  
    console.log("In handle submit method");
    event.preventDefault();
    
    if (this.validateForm(this.state.user)) {
   
    let userCopy = JSON.parse(JSON.stringify(this.state.user));
   //make changes to usercopy //When user choose the default value handleChange event will not triggered
   //console.log("document.getElementById(doj).value;"+document.getElementById("doj").value);
   
   //update user state with usercopy  
   
   
console.log(JSON.stringify(userCopy));
    fetch(API_URL_AUTHENTICATE,{
        method:'POST',
        body:JSON.stringify(userCopy),
        headers:{
          'Access-Control-Allow-Origin':ACCESS_ORGIN,
          'Content-Type':'application/json',
                                      
          }

    }).then(response=>{
      if(response.ok)
      {
		response.json().then(result=>{
			let resultdata=JSON.parse(JSON.stringify(result));
            console.log("Login data"+JSON.stringify(result));
               //this.setState(()=>({
                //data:resultdata
               //}),console.log(this.state.data));
				userContext.token = resultdata.token;
				userContext.username = resultdata.username;
				
							 this.props.history.push({
				pathname: "/admin/dashboard",
				state: {token: resultdata.token,username:resultdata.username}
				});
			//userContext=resultdata.token;
			
        });    
		
		console.log(response)
      }//STATUS_OK   
      else{
	    //this.props.history.push("/csc/error");
        //throw new Error(response.status)
 		let resmsg=this.state.resmsg;
		resmsg.error = "Invalid username and password";
		this.setState({resmsg: resmsg});
		this.setState({showResult:true})
		

      } 
    }).catch((error)=>{
	console.log(error);
      this.props.history.push("/csc/error");
    });
  }//if
   
  }
  validateForm(userdetail) {
      let errors = this.state.errors;
      let formIsValid = true;

      if (!userdetail.username) {
        formIsValid = false;
        errors.username="*Enter User Name";
     }
     
    
        if (!document.getElementById("password").value) {
        formIsValid = false;
        errors["password"]="*Enter password";
     }
      
      

    this.setState(()=>({
        errors: errors
      }), console.log(this.state.errors));     
      
      return formIsValid;


    }
  
  render(){
      
    return (
    
		
        <div className="content">
          <Row>            
            <Col md="8">
			
              <Card className="card-user">
					
                <CardHeader>
				<CardTitle><img src={logo}/></CardTitle>	
                  <CardTitle tag="h3">Login</CardTitle>
                </CardHeader>              
                <CardBody>
					{
					this.state.showResult ? <UncontrolledAlert color="danger"  fade={false}  id="failure">
                            {this.state.resmsg.error}
                     </UncontrolledAlert>:null
                 }
                  <Form name="signupfrm" id="signupfrm">
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <label>User Name</label>
                          <Input
                            defaultValue=""
                            placeholder="UserName"
                            type="text"
                            id="username"
                            name="username"
                            onChange={this.handleChange}
                            requiredText="Enter User Name"
                            required
                          />
                           <div className="text-danger">{this.state.errors.username}</div>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label>Password</label>
                          <Input
                            defaultValue=""
                            placeholder="Password"
                            type="password"
                            name="password"
                            id="password"
                            onChange={this.handleChange}
                          />
                          <div className="text-danger">{this.state.errors.password}</div>                         
                        </FormGroup>
                      </Col>
                     </Row>
                    
                    <Row>
                      <div className="update ml-auto mr-auto">
                        <Button
                          className="btn-round"
                          color="primary"
                          type="submit"
                          onClick={this.handleSubmit}
                        >
                          Login
                        </Button>
                      </div>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      
    ); //Return
  }//Render
}//Class


export default SignIn;
