import React, { Component } from "react";
import QrReader from "react-qr-reader";
import { API_URL_PUNCH_ATTENDANCE, ACCESS_ORGIN } from "./Constants";
import { userContext } from "../views/SignIn.jsx";
//import mp3 from "../attendance.mp3";

// let audio = new Audio("attendance.mp3");
class AttendanceRegistry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      delay: 300,
      result: "",
      facingMode: "environment",
      showCamera: true,
      attendance: { studentidfk: "", name: "", actualslot: "", entryexit: "" },
    };

    this.handleScan = this.handleScan.bind(this);
    this.handleStart = this.handleStart.bind(this);
    this.handleStop = this.handleStop.bind(this);
  }

  handleStop() {
    this.setState({ showCamera: false });
  }

  handleStart() {
    console.log("about to rerender");
    this.setState({ showCamera: true });
  }

  handleScan(data) {
    if (data) {
      console.log("inside");
      this.setState({ showCamera: false });
      this.setState({
        result: data,
      });
      this.green.play();
      console.log("after play");
      //audio.play();
      let attendanceCopy = JSON.parse(JSON.stringify(this.state.attendance));
      console.log(userContext.token);
      let resultdat = data.split("#");
      attendanceCopy.studentidfk = resultdat[1];
      attendanceCopy.name = resultdat[2] + resultdat[3];
      let curDate = new Date().toISOString();
      console.log("tempDate::---->", new Date().toISOString());
      //yyyy-MM-dd'T'HH:mm:ss

      attendanceCopy.entryexit = curDate;

      //let attendance_data = JSON.parse(JSON.stringify(attendanceCopy));
      console.log("inputdata::", JSON.stringify(attendanceCopy));
      let tokenkey = JSON.parse(JSON.stringify(userContext.token));
      let token = "Bearer " + tokenkey.token;
      fetch(API_URL_PUNCH_ATTENDANCE, {
        method: "POST",
        headers: {
          authorization: token,
          Accept: "application/json",
          "Access-Control-Allow-Origin": {
            "http://localhost:3000": "https://www.kanaaksquare.com:3000",
          },
          "Content-Type": "application/json",
        },
        body: JSON.stringify(attendanceCopy),
      })
        .then((response) => {
          console.log(response);
          if (response.ok) {
            response.json().then((result) => {
              let resultdata = JSON.parse(JSON.stringify(result));
              console.log("Attendance data" + JSON.stringify(resultdata));
            });
          } else {
            //this.props.history.push("/csc/error");
            throw new Error(response.code);
          }
        })
        .catch((error) => {
          console.log("Data Error:" + error);
        });
    }

    this.setState({ showCamera: true });
  }
  handleError(err) {
    console.error(err);
  }

  render() {
    return (
      <div>
        {this.state.showCamera ? (
          <QrReader
            delay={this.state.delay}
            facingMode={this.state.facingMode}
            onError={this.handleError}
            onScan={this.handleScan}
            style={{ width: "200px", height: "200px" }}
          />
        ) : null}
        {/* <audio
          ref={(green) => {
            this.green = green;
          }}
        >
          <source src={mp3} type="audio/mpeg"></source>
        </audio> */}
        <button onClick={this.handleStart}>Start</button>
        <button onClick={this.handleStop}>Stop</button>
      </div>
    );
  }
}

export default AttendanceRegistry;
