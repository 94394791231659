/*!

=========================================================
* Typing Student Management Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.kanaa.com/product/kanaa-dashboard-react
* Copyright 2019 kanaa (https://www.kanaa.com)

* Licensed under Kanaa

* Coded by kanaa

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import ReactToPrint from "react-to-print";

import { userContext } from "../views/SignIn.jsx";
import axios from "axios";
import {
  API_URL_STUDENTS,
  API_URL_STUDENTS_KEY,
  ACCESS_ORGIN,
  API_URL_IMG_PATH,
} from "./Constants";
import logo from "../ksquarelogo.png";

let dummyarray = [];

class User extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      updateprofile: {
        mobile: "",
        address: "",
        city: "",
        county: "",
        country: "",
        zip: "",
      },
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePrint = this.handlePrint.bind(this);
  }
  handlePrint() {
    //console.log("inside handleprint");
    //console.log(this.componentRef);
  }
  componentDidUpdate(prevProps) {
    let tokenkey = JSON.parse(JSON.stringify(userContext.token));
    let token = "Bearer " + tokenkey.token;
    console.log("componente did update");
    console.log(
      "componentDidUpdate product props is",
      this.props.location.searchtextProps
    );
    console.log("Token data::------******", token);
    console.log(prevProps.location.key + " :: " + this.props.location.key);
    if (prevProps.location.key !== this.props.location.key) {
      let key = this.props.location.searchtextProps;
      fetch(API_URL_STUDENTS_KEY + key, {
        method: "GET",
        headers: {
          authorization: token,
          Accept: "application/json",
          "Access-Control-Allow-Origin": {
            "http://localhost:3000": "https://www.kanaaksquare.com:3000",
          },
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.ok) {
            response.json().then((result) => {
              let studentDetails = JSON.parse(JSON.stringify(result));
              console.log("RESULT" + JSON.stringify(result));
              dummyarray = studentDetails;
              //console.log("STUDENT DETAILS FROM DummyArray:: "+dummyarray[0].fname);

              this.setState(
                () => ({
                  data: studentDetails,
                }),
                console.log(this.state.data)
              );
            });
          } else {
            throw new Error(response.code);
          }
        })
        .catch((error) => {
          console.log("Data Error:" + error);
        });
    }
    // this.preventDefault();
  }
  handleSubmit(event) {
    event.preventDefault();
    let studentID = document.getElementById("studentid").innerText;
    let mob = document.getElementById("mobile").value;
    let add = document.getElementById("address").value;
    let cit = document.getElementById("city").value;
    let coun = document.getElementById("county").value;
    //https://www.kanaaksquare.com
    let countr = document.getElementById("country").value;
    let jsondata = {
      mobile: mob,
      address: add,
      city: cit,
      county: coun,
      country: countr,
    };
    console.log(jsondata);
    let tokenkey = JSON.parse(JSON.stringify(userContext.token));
    let token = "Bearer " + tokenkey.token;
    console.log("Token data::------******", token);
    fetch(API_URL_STUDENTS + "/" + studentID, {
      method: "PUT",
      body: jsondata,
      headers: {
        authorization: token,
        Accept: "application/json",
        "Access-Control-Allow-Origin": {
          "http://localhost:3000": "http://kanaaksquare.com:3000:3000",
        },
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((result) => {
            let studentDetails = JSON.parse(JSON.stringify(result));
            console.log("RESULT" + JSON.stringify(result));
            dummyarray = studentDetails;
            console.log("STUDENT DETAILS FROM DummyArray:: " + studentDetails);

            this.setState(
              () => ({
                data: studentDetails,
              }),
              console.log(this.state.data)
            );
          });
        } else {
          throw new Error(response.code);
        }
      })
      .catch((error) => {
        console.log("Data Error:" + error);
      });
  }
  componentDidMount(prevProps) {
    //console.log("componentDidMount product props is", this.props.location.searchtextProps);
    let key;

    if (this.props.location.searchtextProps === undefined) {
      key = "11213";
      console.log("indside");
    } else {
      key = this.props.location.searchtextProps;
    }
    console.log("in usermodule" + key);
    let tokenkey = JSON.parse(JSON.stringify(userContext.token));
    let token = "Bearer " + tokenkey.token;

    fetch(API_URL_STUDENTS_KEY + key, {
      method: "GET",
      /*withCredentials: true,*/
      headers: {
        authorization: token,
        Accept: "application/json",
        "Access-Control-Allow-Origin": {
          "http://localhost:3000": "http://kanaaksquare.com:3000",
        },
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((result) => {
            let studentDetails = JSON.parse(JSON.stringify(result));
            console.log("RESULT" + JSON.stringify(result));
            dummyarray = studentDetails;
            console.log("STUDENT DETAILS FROM DummyArray:: " + studentDetails);

            this.setState(
              () => ({
                data: studentDetails,
              }),
              console.log(this.state.data)
            );
          });
        } else {
          throw new Error(response.code);
        }
      })
      .catch((error) => {
        this.props.history.push("/csc/error");
        console.log("Data Error:" + error);
      });

    // }
    // this.preventDefault();
  }

  render() {
    const dtdata = [];
    const editdata = [];
    const qrdata = [];
    const printdata = [];
    const items = dummyarray.map((item, i) => {
      dtdata.push(
        <div>
          <img
            id="profileimg"
            alt="..."
            className="avatar border-gray"
            src={API_URL_IMG_PATH + item.studentid + "&type=photo"}
          />
          <h5 className="title">
            {item.fname}&nbsp;{item.lname}
          </h5>
          <hr />
          <p>
            <small>
              <i class="nc-icon nc-circle-10" />
              &nbsp;<span id="studentid">{item.studentid}</span>
              &nbsp;&nbsp;&nbsp;&nbsp;
            </small>
            <small>
              <i class="nc-icon nc-email-85" />
              &nbsp;{item.email}&nbsp;&nbsp;&nbsp;&nbsp;
            </small>
            <small>
              <i class="nc-icon nc-badge" />
              &nbsp;{item.course}&nbsp;&nbsp;&nbsp;&nbsp;
            </small>
            <small>
              <i class="nc-icon nc-mobile" />
              &nbsp;{item.mobile}&nbsp;&nbsp;&nbsp;&nbsp;
            </small>
          </p>
        </div>
      );
      qrdata.push(
        <div id="qrpart">
          <div id="qrcodeimg">
            <img
              alt="..."
              className="img-circle img-no-padding img-responsive"
              src={API_URL_IMG_PATH + item.studentid + "&type=qr"}
            />
          </div>
        </div>
      );
      printdata.push(
        <div id="idpart">
          <div class="id-card-holder" ref={(el) => (this.componentRef = el)}>
            <div class="logo-id">
              <img src={logo} />
            </div>
            <div class="photo">
              <img
                id="profileimg"
                alt="..."
                src={API_URL_IMG_PATH + item.studentid + "&type=photo"}
              />
            </div>
            <p className="id-title">
              {item.fname}&nbsp;{item.lname}
            </p>
            <div class="qr-code">
              <img
                width="55px"
                height="55px"
                alt="..."
                src={API_URL_IMG_PATH + item.studentid + "&type=qr"}
              />
              <p className="id-text-muted">www.kanaa.net</p>
              <hr />
              <p className="verysmall">
                VILLAR, Thanjavur-613006
                <br />
                Ph: 9790222259 | E-Mail: ksquare2018@gmail.com
              </p>
            </div>
          </div>
          <ReactToPrint
            trigger={() => (
              <Button
                className="btn btn-round btn-primary"
                onClick={this.handlePrint}
              >
                Print this out!
              </Button>
            )}
            content={() => this.componentRef}
          />
          {/* <PrintComponent
            ref={(el) => (this.componentRef = el)}
            pageStyle="@page { size: A4 portrait;}"
          /> */}
        </div>
      );
      editdata.push(
        <Form name="updateprofile" id="updateprofile">
          <Row>
            <Col md="8">
              <FormGroup>
                <label>Address</label>
                <Input
                  defaultValue={item.address}
                  placeholder="Home Address"
                  type="text"
                  name="address"
                  id="address"
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <label>Phone</label>
                <Input
                  placeholder="Phone Number"
                  type="text"
                  defaultValue={item.mobile}
                  name="mobile"
                  id="mobile"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className="pr-1" md="4">
              <FormGroup>
                <label>City</label>
                <Input
                  defaultValue={item.city}
                  placeholder="City"
                  type="text"
                  name="city"
                  id="city"
                />
              </FormGroup>
            </Col>
            <Col className="px-1" md="4">
              <FormGroup>
                <label>County</label>
                <Input
                  defaultValue={item.county}
                  placeholder="State"
                  type="text"
                  name="county"
                  id="county"
                />
              </FormGroup>
            </Col>
            <Col className="px-1" md="4">
              <FormGroup>
                <label>Country</label>
                <Input
                  defaultValue={item.country}
                  placeholder="Country"
                  type="text"
                  name="country"
                  id="country"
                />
              </FormGroup>
            </Col>

            <Col className="pl-1" md="4">
              <FormGroup>
                <label>Postal Code</label>
                <Input
                  placeholder="ZIP Code"
                  type="number"
                  defaultValue={item.zip}
                  name="zip"
                  id="zip"
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <div className="update ml-auto mr-auto">
              <Button
                className="btn-round"
                color="primary"
                type="submit"
                onClick={this.handleSubmit}
              >
                Update Profile
              </Button>
            </div>
          </Row>
        </Form>
      );
    });
    return (
      <div className="content" id="usercontent">
        <Row>
          <Col md="4">
            <Card className="card-user">
              <div className="image">
                <img alt="..." src={require("../assets/img/mike.jpg")} />
              </div>
              <CardBody>
                <div className="author">{dtdata}</div>
              </CardBody>
              <CardFooter>
                <div className="button-container"></div>
              </CardFooter>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">QR Code ID</CardTitle>
              </CardHeader>
              <CardBody>{qrdata}</CardBody>
            </Card>
          </Col>
          <Col md="8">
            <Card className="card-user">
              <CardHeader>
                <CardTitle tag="h5">Edit Profile</CardTitle>
              </CardHeader>
              <CardBody>{editdata}</CardBody>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">ID Card</CardTitle>
              </CardHeader>
              <CardBody>{printdata}</CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default User;
