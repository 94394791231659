export const API_URL_STUDENTS = "https://www.kanaaksquare.com:9443/api/students";
export const API_URL_STUDENTSLIST =
  "https://www.kanaaksquare.com:9443/api/studentslist";
  export const API_URL_COMPLETE =
  "https://www.kanaaksquare.com:9443/api/complete";
export const API_URL_COURSELIST =
  "https://www.kanaaksquare.com:9443/api/courselist";
export const API_URL_ENROLL = "https://www.kanaaksquare.com:9443/api/enroll";
//export const API_URL_COURSE = "https://www.kanaaksquare.com:9443/api/courselist";
export const API_URL_FEECONFIG = "https://www.kanaaksquare.com:9443/api/fee";

export const API_URL_FEES = "https://www.kanaaksquare.com:9443/api/students/fees";
export const API_URL_ATTENDANCE =
  "https://www.kanaaksquare.com:9443/api/attendance/currentbatch";
export const API_URL_PUNCH_ATTENDANCE =
  "https://www.kanaaksquare.com:9443/api/attendance";
export const API_URL_COURSE_ATTENDANCE="https://www.kanaaksquare.com:9443/api/attendance/courseattendance";
export const API_URL_BATCH_ATTENDANCE =
  "https://www.kanaaksquare.com:9443/api/attendance/batchpresent";
export const API_URL_REGISTER = "https://www.kanaaksquare.com:9443/api/register";
export const API_URL_AUTHENTICATE =
  "https://www.kanaaksquare.com:9443/api/authenticate";
export const API_URL_STUDENTS_KEY =
  "https://www.kanaaksquare.com:9443/api/students/s?k=";
export const API_URL_PAYMENT =
  "https://www.kanaaksquare.com:9443/api/students/payment";
export const API_URL_FORGOTPWD =
  "https://www.kanaaksquare.com:9443/api/forgotpassword";
export const ACCESS_ORGIN =
  "{'https://localhost:3000':'https://www.kanaaksquare.com:3000':'https://www.kanaaksquare.com:3000'}";
export const API_URL_IMG_PATH =
  "https://www.kanaaksquare.com:9443/api/imageDisplay?path=";
export const API_URL_LOGOUT = "https://www.kanaaksquare.com:9443/api/logout";
export const API_URL_HOLIDAY = "https://www.kanaaksquare.com:9443/api/holiday";
export const API_URL_CHANGEPWD =
  "https://www.kanaaksquare.com:9443/api/changepassword";
export const API_URL_FEE_PENDING =
  "https://www.kanaaksquare.com:9443/api/feepending";

//DEV
//export const API_URL_STUDENTS = "http://localhost:9443/api/students";
//export const API_URL_STUDENTSLIST =
//  "http://localhost:9443/api/studentslist";
//  export const API_URL_COMPLETE =
//  "http://localhost:9443/api/complete";
//export const API_URL_COURSELIST =
//  "http://localhost:9443/api/courselist";
//export const API_URL_ENROLL = "http://localhost:9443/api/enroll";

//export const API_URL_FEES = "http://localhost:9443/api/students/fees";
//export const API_URL_ATTENDANCE =
//  "http://localhost:9443/api/attendance/currentbatch";
//export const API_URL_PUNCH_ATTENDANCE =
//  "http://localhost:9443/api/attendance/monthlyreport";
//  export const API_URL_COURSE_ATTENDANCE="http://localhost:9443/api/attendance/courseattendance";
//  export const API_URL_BATCH_ATTENDANCE =
//  "http://localhost:9443/api/attendance/batchpresent";
//export const API_URL_REGISTER = "http://localhost:9443/api/register";
//export const API_URL_AUTHENTICATE =
//  "http://localhost:9443/api/authenticate";
//export const API_URL_STUDENTS_KEY =
//  "http://localhost:9443/api/students/s?k=";
//export const API_URL_PAYMENT =
//  "http://localhost:9443/api/students/payment";
//export const API_URL_FORGOTPWD =
//  "http://localhost:9443/api/forgotpassword";
//export const ACCESS_ORGIN =
//  "{'http://localhost:3000':'http://kanaaksquare.com:3000':'https://www.kanaaksquare.com:3000'}";
//export const API_URL_IMG_PATH =
//  "http://localhost:9443/api/imageDisplay?path=";
//export const API_URL_LOGOUT = "http://localhost:9443/api/logout";
//export const API_URL_HOLIDAY = "http://localhost:9443/api/holiday";
//export const API_URL_CHANGEPWD =
//  "http://localhost:9443/api/changepassword";
//export const API_URL_FEE_PENDING =
//  "http://localhost:9443/api/feepending";
