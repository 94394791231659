 /*!

=========================================================
* Typing Student Management Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.kanaa.com/product/kanaa-dashboard-react
* Copyright 2019 kanaa (https://www.kanaa.com)

* Licensed under Kanaa

* Coded by kanaa

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,  
  Row,
  Col,
  Input
} from "reactstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import {Link, Route,  BrowserRouter as Router } from 'react-router-dom';
import {API_URL_STUDENTS, ACCESS_ORGIN} from "./Constants";
import { userContext } from "../views/SignIn.jsx";

 const columns = [{
  dataField: 'studentid',
  text: 'Reg No',
  headerTitle:true,
  formatter: (cell, row) => {
    return (<Link key={1 + (Math.random() * (100-1))} to={{
      pathname: "user-page",
      searchtextProps: row.studentid }} className="nav-link btn-magnify">
                       {row.studentid}
                    </Link>);
    
  }
}, {
   
  dataField: 'fname',
  lname:'lname',  
  text: 'Name',
  headerTitle:true,
   formatter: (cell, row) => {
     return <p>{row.fname}&nbsp;{row.lname}</p>;
   }
},
 {
  dataField: 'ffname',
  flname:'flname',
  text: 'Father Name',
  headerTitle:true,
  formatter: (cell, row) => {
     return <p>{row.ffname}&nbsp;{row.flname}</p>;
   }
},
 
{
  dataField: 'mobile',
  text: 'Contact Number',
  headerTitle:true
},
{
  dataField: 'email',
  text: 'E-Mail',
  headerTitle:true
}, 
{
  dataField: 'doj',
  text: 'Date of Joining',
  headerTitle:true,
 
  formatter:(cell,row)=>{
    const date1 = new Date(row.doj);
    return <p>{new Intl.DateTimeFormat('en-GB', { 
          year: 'numeric', 
          month: 'short', 
          day: '2-digit' 
        }).format(date1)}</p>
  }
},

{
  dataField: 'dob',
  text: 'Date of Birth',
  
  headerTitle:true,
  formatter:(cell,row)=>{
    const date1 = new Date(row.dob);
    return <p>{new Intl.DateTimeFormat('en-GB', { 
          year: 'numeric', 
          month: 'short', 
          day: '2-digit' 
        }).format(date1)}</p>
  }
},

{
  dataField: 'qualification',
  text: 'Qualification',
  headerTitle:true
 
},
{
  dataField: 'address',
  city:'city',
  zip:'zip',
  county:'county',
  country:'country',
  text: 'Address',
  headerTitle:true,
   formatter: (cell, row) => {
     return <p>{row.address},&nbsp;{row.city}&mdash;&nbsp;{row.zip},&nbsp;{row.county},&nbsp;{row.country}</p>;
   }
  
}
];

 //Dummy data for formatting purpose
 let main_dataarray=[{"studentid": 11102,
     "fname": "Thiru",
     "lname": "K",
     "ffname": "Velu",
     "flname": "N",
     "qualification": "B Tech",
     "dob": 957052800000,
     "email": "thiru@gmail.com",
    
     "mobile": "9790222259",
     "doj": 1589760000000,
    
     "city": "Thanjavur",
     "country": "India",
     "county": "Karnataka",
     "zip": "613006",
     "address": "6, seethanagar",
     "qrpath": "/Users/maatharasikulandaivelu/D/tsms/qrcode/11102.png",
     "searchString": "Thiru K 11102",
     "payments": []}];

class ViewUsers extends React.Component {
  
  constructor(props){
    super(props)
  
    this.state={dataarray:main_dataarray, searchid:"",searchname:"",searchfname:"",
    searchmob:"",searchemail:"",searchdob:"",searchqual:"",searchadd:"",searchdoj:""}
  //const columns=[];
    this.handleChange = this.handleChange.bind(this);
 
  }


  componentWillMount()
	{
		console.log("componenwilldMount*************");
		main_dataarray=[
		    {
		    	"studentid": 11102,
		        "fname": "Thiru",
		        "lname": "K",
		        "ffname": "Velu",
		        "flname": "N",
		        "qualification": "B Tech",
		        "dob": 957052800000,
		        "email": "thiru@gmail.com",
		        
		        "mobile": "9790222259",
		        "doj": 1589760000000,
		        
            
		        "city": "Thanjavur",
		        "country": "India",
		        "county": "Karnataka",
		        "zip": "613006",
		        "address": "6, seethanagar",
		        "qrpath": "/Users/maatharasikulandaivelu/D/tsms/qrcode/11102.png",
		        "searchString": "Thiru K 11102",
		        "payments": []
		    }
		]
		
		this.setState(()=>({
            dataarray:main_dataarray
           }),console.log(this.state.dataarray));
		console.log("main_dataarray", main_dataarray);
		
	}
  
  handleChange(e) {
	    console.log("calling filter function",e.target.value);
		console.log(main_dataarray);
		let  elements  = [];
		elements.push(JSON.parse(JSON.stringify(main_dataarray)));
		console.log("elements",elements);
				let filterstr="";
				console.log(".............", elements)
					filterstr= e.target.value;
				let custom_array=[];
	    		elements.map((key)=>
	    		{
	    			key.map((dataItem,index) =>{
	    				console.log("dataItem",dataItem)
	    				console.log("filterstr:: 1st-->",filterstr)
		    			console.log("KEY",dataItem.studentid)
		    			
		    		
		    			
		    			let findkey="";
	    				if(e.target.name=="filid")
	    					{
		    				findkey=dataItem.studentid+"";
		    				this.setState({ searchid: e.target.value });
	    					}
	    				else if(e.target.name=="filname")
	    					{
	    					findkey=dataItem.fname+"";
	    					this.setState({ searchname: e.target.value });
	    					}
	    				else if(e.target.name=="filfname")
    					{
    					findkey=dataItem.ffname+"";
    					this.setState({ searchfname: e.target.value });
    					}
	    					else if(e.target.name=="filqual")
	    					
    					{
    					findkey=dataItem.qualification+"";
    					this.setState({ searchcourse: e.target.value });
    					}
	    				else if(e.target.name=="filmob")
	    					{
	    					findkey=dataItem.mobile+"";
	    					this.setState({ searchmob: e.target.value });
	    					}
	    				else if(e.target.name=="filemail")
    					{
    					findkey=dataItem.email+"";
    					this.setState({ searchemail: e.target.value });
    					}
	    				else if(e.target.name=="fildoj")
	    					{
	    					findkey=dataItem.doj+"";
	    					this.setState({ searchdoj: e.target.value });
	    					}
	    				else if(e.target.name=="fildob")
    					{
    					findkey=dataItem.dob+"";
    					this.setState({ searchdob: e.target.value });
    					}
	    				else if(e.target.name=="filadd")
    					{
    					findkey=dataItem.address+"";
    					this.setState({ searchadd: e.target.value });
    					}
		    			console.log("findkey:: ",findkey)
		    			console.log("filterstr:: ",e.target.value)
		    			if(findkey.includes(e.target.value))
		    				{
		    				//console.log("tru@@@@e");
		    				custom_array.push(dataItem);
		    				}
		    			else
		    				{
		    				//console.log("false");
		    				}
	    			});//child map(map of map)		
	    		//console.log("data value",selectedProduct);
	    		});	    //parent map
	    		this.setState(()=>({
	                dataarray:custom_array
	               }),console.log(this.state.dataarray));
	    		console.log("custom_array", custom_array);
	  }
  
  componentDidMount()
  {
	  let tokenkey=JSON.parse(JSON.stringify(userContext.token));
		let token='Bearer ' +tokenkey.token;
   fetch(API_URL_STUDENTS,{
      method:"GET",
      headers:{
    		authorization: token,
    	  	Accept:"application/json",
            'Access-Control-Allow-Origin':{'http://localhost:3000':'http://kanaaksquare.com:3000'},
            'Content-Type':'application/json',
			 }
      }
      ).then(response=>{
        if(response.ok){
          response.json().then(result=>{
           let studentDetails=JSON.parse(JSON.stringify(result));
                this.setState(()=>({
                        dataarray: studentDetails
                      }), console.log(this.state.data));   
            });                
          
        }
        else{throw new Error(response.code)}

      }).catch((error)=>{
           console.log('Data Error:'+error);
      });
   var len= document.getElementById("studentlist").rows[0].cells[0].offsetWidth;
   console.log("len",len)
   document.getElementById("filid").style.width=(len-10)+'px';
   len= document.getElementById("studentlist").rows[0].cells[1].offsetWidth;
   document.getElementById("filname").style.width=(len-10)+'px';
   len= document.getElementById("studentlist").rows[0].cells[2].offsetWidth;
   document.getElementById("filfname").style.width=(len-10)+'px';
   len= document.getElementById("studentlist").rows[0].cells[3].offsetWidth;
   document.getElementById("filmob").style.width=(len-10)+'px';
   len= document.getElementById("studentlist").rows[0].cells[4].offsetWidth;
   document.getElementById("filemail").style.width=(len-10)+'px';
   len= document.getElementById("studentlist").rows[0].cells[5].offsetWidth;
   document.getElementById("fildoj").style.width=(len-10)+'px';
   len= document.getElementById("studentlist").rows[0].cells[6].offsetWidth;
   document.getElementById("fildob").style.width=(len-10)+'px';
   len= document.getElementById("studentlist").rows[0].cells[7].offsetWidth;
   document.getElementById("filqual").style.width=(len-10)+'px';
   len= document.getElementById("studentlist").rows[0].cells[8].offsetWidth;
   document.getElementById("filadd").style.width=(len-10)+'px';
    
  }
  render() {
    return (
      
        <div className="content">
          <Row>
            <Col md="12">
              <Card className="card-plain">
                <CardHeader>
                  <CardTitle tag="h4">Students Details</CardTitle>
                  <p className="card-category">
                    Enter search text to filter by column
                  </p>
                </CardHeader>
                <CardBody>
                <div className="react-bootstrap-table" style={{height:'70px'}}>
                 <table id="filtable" className="table table-bordered">
                <tbody>
             	<tr>
             	
             	<td><Input id='filid' name='filid' type='test' placeholder="Regno"
             		value={this.state.searchid} onChange= {this.handleChange}></Input></td>
  			 	<td><Input id='filname' name='filname' type='test' placeholder="Name"
	  	    	 	value={this.state.searchname}  onChange={this.handleChange}></Input></td>
	  	    	 	<td><Input id='filfname' name='filfname' type='test' placeholder="FatherName"
		  	    	 	value={this.state.searchfname}  onChange={this.handleChange}></Input></td>
             
	  	    	<td><Input id='filmob' name='filmob' type='test' placeholder="Mobile"
  	    	 		value={this.state.searchmob}  onChange={ this.handleChange }></Input></td>
  	    	 	<td><Input id='filemail' name='filemail' type='test' placeholder="E-mail"
  	  	    	 		value={this.state.searchemail}  onChange={ this.handleChange }></Input></td>
  	  	    		<td><Input id='fildoj' name='fildoj' type='test' placeholder="Joining Date"
  	  	    	 		value={this.state.searchdoj}  onChange={ this.handleChange }></Input></td>
  	  	    		<td><Input id='fildob' name='fildob' type='test' placeholder="Birth Date"
  	  	    	 		value={this.state.searchdob}  onChange={ this.handleChange }></Input></td>
  	  	    	 	 	
  	    	 		 	
  	  	    	 	<td><Input id='filqual' name='filqual' type='test' placeholder="Qualification"
  	  	    	 		value={this.state.searchqual}  onChange={ this.handleChange } ></Input></td>
  	  	    	 	<td><Input id='filadd' name='filadd' type='test' placeholder="Address"
  	  	    	 		value={this.state.searchadd}  onChange={ this.handleChange } ></Input></td>
  	  	    	
  	    	 	</tr>
  	    	 	</tbody>
             	</table>      
                 </div>
			     <BootstrapTable id="studentlist"  keyField='studentid' data={ this.state.dataarray } columns={ columns }  striped
			  hover
			  condensed table-responsive>
			     
			     </BootstrapTable>
               
                  

                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      
    );
  }
}

export default ViewUsers;
