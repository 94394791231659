/*!

=========================================================
* Typing Student Management Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.kanaa.com/product/kanaa-dashboard-react
* Copyright 2019 kanaa (https://www.kanaa.com)

* Licensed under Kanaa

* Coded by kanaa

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

import "../src/assets/demo/demo.css"

import '../src/assets/css/kanaa-dashboard.css';
import "bootstrap/dist/css/bootstrap.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import style from '/src/assets/scss/kanaa-dashboard.scss';
import "/src/assets/scss/kanaa-dashboard.scss?v=1.1.0";

import "../src/assets/css/custom.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import AdminLayout from "./layouts/Admin.jsx";
import LoginLayout from "./layouts/Login.jsx";
//import Dashboard from "./views/Dashboard";
//import SignUp from "./views/SignUp.jsx";

const hist = createBrowserHistory();

const Home = ({match}) => {
 
  return(
    <Switch>
      <Route path={match.url} render={(props) => <LoginLayout {...props} />} />
    </Switch>
  );
};

const Main = ({match}) => {
 
  return(
    <Switch>
      <Route path={match.url} render={(props) => <AdminLayout {...props} />} />
    </Switch>
  );
};

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/csc" component={Home} />
      <Route path="/admin" component={Main} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
