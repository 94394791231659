/*!

=========================================================
* Student Registration - v1.1.0
=========================================================
* Copyright 2019 kanaa (http://www.kanaa.net)

@author: Maatharasi Kulandaivelu
@Since 27/11/2019
=========================================================



*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  Alert,
} from "reactstrap";
//import Table from "../variables/table.jsx";
import {
  API_URL_STUDENTS,
  ACCESS_ORGIN,
  API_URL_COURSELIST,
} from "./Constants";
import { userContext } from "../views/SignIn.jsx";
//import ScriptTag from 'react-script-tag';
import Dropzone from "react-dropzone";

//import {js} from "./drag.js" ;

class Registration extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onDrop = this.onDrop.bind(this);
    //this.onCancel=this.onCancel.bind(this);
    // this.state={result:[]};
    this.state = {
      coursearray: [],
      file: null,
      fcontent: null,
      student: {
        fname: "",
        lname: "",
        ffname: "",
        flname: "",
        dob: "",
        doj: "",
        qualification: "",
        email: "",
        mobile: "",
        course: "",
        address: "",
        city: "",
        county: "",
        country: "",
        zip: "",
        timeslot: "",
      },
      errors: {
        fname: "",
        lname: "",
        ffname: "",
        flname: "",
        dob: "",
        qualification: "",
        email: "",
        mobile: "",
        address: "",
        zip: "",
        timeslot: "",
      },
      resmsg: { success: "", error: "" },
      resultdata: {},
    };
  }

  onDrop = (acceptedFiles) => {
    console.log(acceptedFiles);
    this.setState({
      file: URL.createObjectURL(acceptedFiles[0]),
    });
    this.setState({
      fcontent: acceptedFiles[0],
    });
  };

  handleChange(event) {
    let value = event.target.value;
    let name = event.target.name;
    this.setState(
      (prevState) => ({
        student: {
          ...prevState.student,
          [name]: value,
        },
      }),
      () => console.log(this.state.student)
    );
  }
  handleClearForm(e) {
    e.preventDefault();
    this.setState({
      student: {
        filedata: null,
        fname: "",
        lname: "",
        ffname: "",
        flname: "",
        dob: "",
        doj: "",
        qualification: "",
        email: "",
        mobile: "",
        course: "",
        address: "",
        city: "",
        county: "",
        country: "",
        zip: "",
      },
    });
  }

  handleSubmit(event) {
    console.log("In handle submit method");
    event.preventDefault();

    if (this.validateForm(this.state.student)) {
      let studentCopy = JSON.parse(JSON.stringify(this.state.student));

      //make changes to studentcopy //When user choose the default value handleChange event will not triggered
      console.log(
        "document.getElementById(doj).value;" +
          document.getElementById("doj").value
      );
      studentCopy.doj = document.getElementById("doj").value;
      studentCopy.city = document.getElementById("city").value;
      studentCopy.county = document.getElementById("county").value;
      studentCopy.country = document.getElementById("country").value;

      let studentEnroll = { courseid: "", timeslot: "", enrolledon: "" };

      studentEnroll.courseid = document.getElementById("course").value;
      studentEnroll.timeslot = document.getElementById("timeslot").value;
      studentEnroll.enrolledon = document.getElementById("doj").value;
      // studentCopy.filedata=this.state.fcontent;
      //update student state with studentcopy
      console.log(JSON.stringify(studentCopy));
      let tokenkey = JSON.parse(JSON.stringify(userContext.token));
      let token = "Bearer " + tokenkey.token;
      console.log(JSON.stringify(studentCopy));

      const formData = new FormData();
      formData.append("file", this.state.fcontent);
      formData.append(
        "json",
        new Blob([JSON.stringify(studentCopy)], { type: "application/json" })
      );
      formData.append(
        "enroll",
        new Blob([JSON.stringify(studentEnroll)], { type: "application/json" })
      );

      console.log("form.getHeaders():", formData._boundary);
      fetch(API_URL_STUDENTS, {
        method: "POST",
        body: formData,
        headers: {
          authorization: token,
          Accept: "application/json",
          "Access-Control-Allow-Origin": "https://www.kanaaksquare.com:3000",
        },
      })
        .then((response) => {
          if (response.ok) {
            response.json().then((result) => {
              document.getElementById("registerform").reset();
              let resmsg = this.state.resmsg;
              let studentDetails = JSON.parse(JSON.stringify(result));
              resmsg.success =
                "Student " +
                studentDetails.fname +
                " successfully registered, StudentID is " +
                studentDetails.studentid;
              document.getElementById("success").style.backgroundColor =
                "#7ed6a5";
              document.getElementById("failure").style.backgroundColor =
                "#ffffff";
              resmsg.failure = "";

              this.setState(
                () => ({
                  resmsg: resmsg,
                }),
                console.log(this.state.resmsg)
              );
            });
          } //STATUS_OK
          else {
            throw new Error(response.status);
          }
        })
        .catch((error) => {
          console.log("error:" + error);
          let resmsg = this.state.resmsg;
          resmsg.error = "Error While registring the student";
          document.getElementById("failure").style.backgroundColor = "#f1926e";
          document.getElementById("success").style.backgroundColor = "#ffffff";
          resmsg.success = "";
          this.setState(
            () => ({
              resmsg: resmsg,
            }),
            console.log(this.state.resmsg)
          );
        });
    } //if
  }
  validateForm(studentdetail) {
    let errors = this.state.errors;
    let formIsValid = true;

    if (!studentdetail.fname) {
      formIsValid = false;
      errors.fname = "*Enter First Name";
    }
    if (typeof document.getElementById("fname").value !== "undefined") {
      if (!document.getElementById("fname").value.match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors.fname = "*Please enter alphabet characters only.";
      }
    }
    if (!document.getElementById("lname").value) {
      formIsValid = false;
      errors.lname = "*Enter Last Name";
    }
    if (typeof document.getElementById("lname").value !== "undefined") {
      if (!document.getElementById("lname").value.match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors.lname = "*Please enter alphabet characters only.";
      }
    }
    if (!document.getElementById("ffname").value) {
      formIsValid = false;
      errors.ffname = "*Enter Father's First Name";
    }
    if (typeof document.getElementById("ffname").value !== "undefined") {
      if (!document.getElementById("ffname").value.match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors.ffname = "*Please enter alphabet characters only.";
      }
    }
    if (!document.getElementById("flname").value) {
      formIsValid = false;
      errors.flname = "*Enter Father's Last Name";
    }
    if (typeof document.getElementById("flname").value !== "undefined") {
      if (!document.getElementById("flname").value.match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors.flname = "*Please enter alphabet characters only.";
      }
    }

    if (!document.getElementById("email").value) {
      formIsValid = false;
      errors["email"] = "*Please enter your email-ID.";
    }

    if (typeof document.getElementById("email").value !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(document.getElementById("email").value)) {
        formIsValid = false;
        errors["emailid"] = "*Please enter valid email-ID.";
      }
    }

    if (!document.getElementById("mobile").value) {
      formIsValid = false;
      errors["mobile"] = "*Please enter your mobile no.";
    }

    if (typeof document.getElementById("mobile").value !== "undefined") {
      if (!document.getElementById("mobile").value.match(/^[0-9]{10}$/)) {
        formIsValid = false;
        errors["mobile"] = "*Please enter valid mobile no.";
      }
    }

    if (!document.getElementById("address").value) {
      formIsValid = false;
      errors.address = "*Enter Address";
    }
    if (!document.getElementById("qualification").value) {
      formIsValid = false;
      errors.qualification = "*Enter Highest Degree UG/PG";
    }

    if (!document.getElementById("zip").value) {
      formIsValid = false;
      errors["zip"] = "*Enter Postal/ZIP code";
    }

    if (typeof document.getElementById("zip").value !== "undefined") {
      if (!document.getElementById("zip").value.match(/^[0-9]{6}$/)) {
        formIsValid = false;
        errors["zip"] = "*Please enter valid Postal/ZIP code";
      }
    }

    if (!document.getElementById("dob").value) {
      formIsValid = false;
      errors["dob"] = "*Please select Date of Birth";
    }
    if (!document.getElementById("timeslot").value) {
      formIsValid = false;
      errors["timeslot"] = "*Please select the slot";
    }

    this.setState(
      () => ({
        errors: errors,
      }),
      console.log(this.state.errors)
    );

    return formIsValid;
  }

  componentDidMount() {
    let tokenkey = JSON.parse(JSON.stringify(userContext.token));
    let token = "Bearer " + tokenkey.token;
    fetch(API_URL_COURSELIST, {
      method: "GET",
      headers: {
        authorization: token,
        Accept: "application/json",
        "Access-Control-Allow-Origin": {
          "http://localhost:3000": "https://www.kanaaksquare.com:3000",
        },
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((result) => {
            let feedata = JSON.parse(JSON.stringify(result));

            this.setState(
              {
                coursearray: feedata,
              },
              console.log(this.state.coursearray)
            );
          });
          console.log("Value of data" + this.data);
        } else {
          throw new Error(response.code);
        }
      })
      .catch((error) => {
        console.log("Data Error:" + error);
      });
  }

  render() {
    var curr = new Date();
    curr.setDate(curr.getDate() + 3);
    var date = curr.toISOString().substr(0, 10);
    return (
      <div className="content">
        <Row>
          <Col md="8">
            <Card className="card-user">
              <CardHeader>
                <CardTitle tag="h5">New Student Registration Form</CardTitle>
              </CardHeader>
              <CardBody>
                <Alert
                  color="success"
                  id="success"
                  style={{ "background-color": "#ffffff" }}
                >
                  {this.state.resmsg.success}
                </Alert>
                <Alert
                  color="danger"
                  id="failure"
                  style={{ "background-color": "#ffffff" }}
                >
                  {this.state.resmsg.error}
                </Alert>

                <Form
                  name="registerform"
                  id="registerform"
                  encType="multipart/form-data"
                >
                  <Row>
                    <Col md="6">
                      <Dropzone onDrop={this.onDrop}>
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            Drag & Drop Passport Photo
                            <img
                              src={this.state.file}
                              style={{
                                height: "35mm",
                                border: "1px solid black",
                                width: "35mm",
                              }}
                            />
                          </div>
                        )}
                      </Dropzone>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <label>First Name</label>
                        <Input
                          defaultValue=""
                          placeholder="First Name"
                          type="text"
                          id="fname"
                          name="fname"
                          onChange={this.handleChange}
                          requiredText="Enter First Name"
                          required
                        />
                        <div className="text-danger">
                          {this.state.errors.fname}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label>Last Name</label>
                        <Input
                          defaultValue=""
                          placeholder="Last Name"
                          type="text"
                          name="lname"
                          id="lname"
                          onChange={this.handleChange}
                        />
                        <div className="text-danger">
                          {this.state.errors.lname}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <label>Father's First Name</label>
                        <Input
                          defaultValue=""
                          placeholder="First Name"
                          type="text"
                          name="ffname"
                          id="ffname"
                          onChange={this.handleChange}
                        />
                        <div className="text-danger">
                          {this.state.errors.ffname}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label>Father's Last Name</label>
                        <Input
                          defaultValue=""
                          placeholder="Last Name"
                          type="text"
                          name="flname"
                          id="flname"
                          onChange={this.handleChange}
                        />
                        <div className="text-danger">
                          {this.state.errors.flname}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label for="dob">Date of Birth</Label>
                        <Input
                          type="date"
                          name="dob"
                          id="dob"
                          placeholder="date placeholder"
                          onChange={this.handleChange}
                        />
                        <div className="text-danger">
                          {this.state.errors.dob}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label for="doj">Date of Joining</Label>
                        <Input
                          type="date"
                          name="doj"
                          id="doj"
                          defaultValue={date}
                          value={this.state.doj}
                          placeholder="date placeholder"
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label for="mobile">Mobile Phone Number</Label>
                        <Input
                          defaultValue=""
                          placeholder="Phone Number"
                          type="number"
                          id="mobile"
                          name="mobile"
                          onChange={this.handleChange}
                        />
                        <div className="text-danger">
                          {this.state.errors.mobile}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label for="email">E-Mail</Label>
                        <Input
                          defaultValue=""
                          placeholder="email"
                          type="text"
                          id="email"
                          name="email"
                          onChange={this.handleChange}
                        />
                        <div className="text-danger">
                          {this.state.errors.email}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <Label for="timeslot">Time Slot</Label>
                      <Input
                        type="select"
                        name="timeslot"
                        id="timeslot"
                        onChange={this.handleChange}
                      >
                        <option>7:00-8:00 AM</option>
                        <option>7:00-8:00 AM</option>
                        <option>8:00-9:00 AM</option>
                        <option>9:00-10:00 AM</option>
                        <option>10:00-11:00 AM</option>
                        <option>11:00-12:00 AM</option>
                        <option>12:00-1:00 PM</option>
                        <option>1:00-2:00 PM</option>
                        <option>3:00-4:00 PM</option>
                        <option>4:00-5:00 PM</option>
                        <option>5:00-6:00 PM</option>
                        <option>6:00-7:00 PM</option>
                        <option>7:00-8:00 PM</option>
                      </Input>
                      <div className="text-danger">
                        {this.state.errors.timeslot}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label for="course">Course</Label>
                        <Input
                          type="select"
                          name="course"
                          id="course"
                          onChange={this.handleChange}
                        >
                          {this.state.coursearray.map((item, key) => (
                            <option value={item.courseid}>
                              {item.coursename}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label for="qualification">Qualification</Label>
                        <Input
                          type="text"
                          name="qualification"
                          id="qualification"
                          onChange={this.handleChange}
                          placeholder="UG/PG Details"
                        ></Input>
                        <div className="text-danger">
                          {this.state.errors.qualification}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12}>
                      <FormGroup>
                        <Label for="Address">Address</Label>
                        <Input
                          type="textarea"
                          name="address"
                          id="address"
                          onChange={this.handleChange}
                        />
                        <div className="text-danger">
                          {this.state.errors.address}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="4">
                      <FormGroup>
                        <label>City</label>
                        <Input
                          defaultValue="Melbourne"
                          value={this.state.city}
                          placeholder="City"
                          type="text"
                          name="city"
                          id="city"
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-1" md="4">
                      <FormGroup>
                        <label>County/State</label>
                        <Input
                          defaultValue="Melbourne"
                          placeholder="State"
                          value={this.state.county}
                          type="text"
                          name="county"
                          id="county"
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="px-1" md="4">
                      <FormGroup>
                        <label>Country</label>
                        <Input
                          defaultValue="Australia"
                          placeholder="Country"
                          value={this.state.country}
                          name="country"
                          id="country"
                          type="text"
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-1" md="4">
                      <FormGroup>
                        <label>Postal Code</label>
                        <Input
                          placeholder="ZIP Code"
                          type="number"
                          onChange={this.handleChange}
                          name="zip"
                          id="zip"
                        />
                        <div className="text-danger">
                          {this.state.errors.zip}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <div className="update ml-auto mr-auto">
                      <Button
                        className="btn-round"
                        color="primary"
                        type="submit"
                        onClick={this.handleSubmit}
                      >
                        Update Profile
                      </Button>
                    </div>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    ); //Return
  } //Render
} //Class
export default Registration;
