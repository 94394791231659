 /*!

=========================================================
* Typing Student Management Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.kanaa.com/product/kanaa-dashboard-react
* Copyright 2019 kanaa (https://www.kanaa.com)

* Licensed under Kanaa

* Coded by kanaa

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,  
  Row,
  Col,
  Button
} from "reactstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory  from 'react-bootstrap-table2-filter';
import {CSVLink, CSVDownload} from 'react-csv';
import {API_URL_FEES, ACCESS_ORGIN} from "./Constants";
//import cellEditFactory, {Type} from 'react-bootstrap-table2-editor';
//import paginationFactory from 'react-bootstrap-table2-paginator';
//{ textFilter, dateFilter }
import { userContext } from "../views/SignIn.jsx";

 const columns = [{
  dataField: 'studentid',
  text: 'Reg No',
  headerTitle:(column, colIndex) => `Registration Number`,
  //filter: textFilter(),

}, {
   
  dataField: 'fname',
  lname:'lname',  
  text: 'Name',
  headerTitle:(column, colIndex) => `First Name & Last Name`,

  //filter: textFilter(),
   formatter: (cell, row) => {
     return <p>{row.fname}&nbsp;{row.lname}</p>;
   }
},
 
 
{
  dataField: 'course',
  text: 'Course',
  headerTitle:true,
  //filter: textFilter(),
 
},
{
  dataField: 'mobile',
  text: 'Mobile',
  headerTitle:(column, colIndex) => `Contact Number`,
  
 
}

];


  const expcolumns = [{
  
  dataField:'paymentid',
  text: 'PayID',
  headerTitle:(column, colIndex) => `Payment ID`
  
},  
{
  dataField:'paymentdate',
  text: 'Paid Date',  
  headerTitle:(column, colIndex) => `Last Payment Date`,    
     formatter:(cell,row)=>{   
       let date1 = new Date();
              date1 = new Date(row.paymentdate);
              console.log("formated date@@@@@@", date1);
           return <p>{new Intl.DateTimeFormat('en-GB', { 
            year: 'numeric', 
            month: 'short',          
            day: '2-digit' 
          }).format(date1)}</p>
    } 
  
},
{
  dataField:'amount',
  text: 'Amount',
  headerTitle:true  
  
},
{
  dataField:'studentidfk',
  text: 'Student',
  headerTitle:true, 
  hidden:true
}


];
  let childdata=[];
  let csvdata=[];


class PaymentDetails extends React.Component {
  
  constructor(props){
    super(props)
  this.state = { data: [], checkrow:[],exdata:[], expanded: [0, 1]}; 
 
  }

  
  


  componentDidMount()
  {
	  console.log("USER CONTEXT:: "+userContext.token);
	  let tokenkey=JSON.parse(JSON.stringify(userContext.token));
	  let token='Bearer ' +tokenkey.token;
	  fetch(API_URL_FEES,{
      method:"GET",
      headers:{
    	  authorization: token,
    	  	Accept:"application/json",
            'Access-Control-Allow-Origin':{'http://localhost:3000':'http://kanaaksquare.com:3000'},
            'Content-Type':'application/json'
            	}
      }
      ).then(response=>{
        if(response.ok){
          response.json().then(result=>{
           let studentDetails=JSON.parse(JSON.stringify(result));
           console.log("Export Data*******"+(JSON.stringify(studentDetails)));
           let payobj=[];
                this.setState(()=>({
                        data: studentDetails                      
                      }), console.log(this.state.data));
                      
                let studenpayments=studentDetails.map((item, index) => {
                       if(item!=null){ 
                      let spayments =item.payments.map((pays,i)=>{
                        if(!(pays.paymentid===null))
                        {
                         let jsonobj={"NAME":item.fname+" "+item.lname,"COURSE":item.course,"MOBILE":item.mobile,"PAYMENT ID":pays.paymentid,"PAID ON":pays.paymentdate,"AMOUNT":pays.amount}
                         csvdata.push(jsonobj); 
                         payobj.push(pays);
                         console.log("Pays"+JSON.stringify(csvdata));
                        } 
                         return console.log(pays);
                      })                      
                    }
                     return console.log(item);
              })                    
                 let payfinal=JSON.parse(JSON.stringify(payobj));
                 //console.log(payfinal);
                 childdata=payfinal;                            
                 this.setState(()=>({
                         exdata: payfinal                     
                      }), console.log(this.state.exdata));          
            });                
          
        }
        else{throw new Error(response.code)}

      }).catch((error)=>{
           console.log('Data Error:'+error);
      });
      
    
  }
   handleOnExpand = (row, isExpand, rowIndex, e) => {
     console.log("in handleon Expande method");
      }
      
  render() {
    
    const expandRow = {
      renderer: function(cell,row) {
        let i=0;
        
         console.log("DETA::"+JSON.stringify(childdata));
         const dtdata = [];
          const items= childdata.map((item,i) => {
             
              if(cell.studentid==item.studentidfk)
              {
               dtdata.push(<tr><td>{item.paymentid}</td><td>{item.amount}</td><td>{new Date(item.paymentdate).toISOString()}</td></tr>);
              }
               
             });
             
            
       /*  for(let k=0;k<1;k++)
        {
         if(cell.studentDetails===childdata[k].paymentid)
        {
         // childdata[k].paymentid=this.childdata[k].paymentid;
        //  childdata[k].studentidfk=this.childdata[k].studentidfk;
        console.log(":insid if");
        } 
        } */
        return(
       
        <div> 
          {/*  {(() => {
          const dtdata = [];

          for (let j = 0; j <= i; j++) {
           // dtdata.push(<td>{childdata[j].paymentid</td>);
           // dtdata.push(<td>{this.state.exdata[j].studentidfk}</td>);
          }

          return dtdata;
        })()} */}
            <table class="expanded"><thead><th>PaymentId</th><th>Amount</th><th>Paid On</th></thead><tbody>{dtdata}</tbody></table>
        </div>
        )
      }
      
    };
    return (
         <div className="content">
          <Row>
            <Col md="12">
              <Card className="card-plain">
                <CardHeader>
                  <CardTitle tag="h4">Payment Details</CardTitle>
                  <p>
                    Full Payment details of all the studends. 
                    Export Entire Payment Details of all students
                  </p>
                   <CSVLink color="primary"  className="btn btn-round btn-primary" data={csvdata} filename={"Ksquare_typing_payment.csv"}>
                    Export CSV</CSVLink>
                </CardHeader>
                <CardBody>
                      <BootstrapTable  
                      classes="customdesign"
                    id='studentid' 
                    keyField='studentid' 
                    data={ this.state.data } 
                    columns={ columns }    
                     
                      expandRow={ expandRow }
                    table-responsive>                    
                    </BootstrapTable>
                  
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      
    );
  }
}

export default PaymentDetails;