/*!

=========================================================
* user Registration - v1.1.0
=========================================================
* Copyright 2019 kanaa (http://www.kanaa.net)

@author: Maatharasi Kulandaivelu
@Since 27/11/2019
=========================================================



*/
import React, { useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
//import Table from "../variables/table.jsx";
import { API_URL_REGISTER, ACCESS_ORGIN } from "./Constants";

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    //this.onCancel=this.onCancel.bind(this);
    // this.state={result:[]};
    this.state = {
      user: {
        username: "",
        password: "",
        mobile: "",
        question: "",
        answer: "",
      },
      errors: {
        username: "",
        password: "",
        mobile: "",
        question: "",
        answer: "",
      },
      resmsg: { success: "", error: "" },
      resultdata: {},
      ShowToggle: false,
    };

    //let [modal, setModal]=useState(false);
    //let toggle = () => setModal(!modal);
  }

  handleClose(event) {
    document.getElementById("modaldata").hidden = true;
    let ShowToggle = this.state.ShowToggle;
    this.setState(
      () => ({
        ShowToggle: false,
      }),
      console.log(this.state.ShowToggle)
    );
    event.preventDefault();
    this.setState({
      user: {
        username: "",
        password: "",
        mobile: "",
        question: "",
        answer: "",
      },
    });
    this.props.history.push("/csc/login");
  }

  handleChange(event) {
    let value = event.target.value;
    let name = event.target.name;
    this.setState(
      (prevState) => ({
        user: {
          ...prevState.user,
          [name]: value,
        },
      }),
      () => console.log(this.state.user)
    );
  }
  handleClearForm(e) {
    e.preventDefault();
    this.setState({
      user: {
        username: "",
        password: "",
        mobile: "",
        question: "",
        answer: "",
      },
    });
  }
  handleSubmit(event) {
    console.log("In handle submit method");
    event.preventDefault();

    if (this.validateForm(this.state.user)) {
      let userCopy = JSON.parse(JSON.stringify(this.state.user));
      //make changes to usercopy //When user choose the default value handleChange event will not triggered
      //console.log("document.getElementById(doj).value;"+document.getElementById("doj").value);

      //update user state with usercopy

      console.log(JSON.stringify(userCopy));
      fetch(API_URL_REGISTER, {
        method: "POST",
        body: JSON.stringify(userCopy),
        headers: {
          Accept: "image/png",
          "Access-Control-Allow-Origin": {
            "http://localhost:3000": "https://www.kanaaksquare.com:3000",
            "http://localhost:5000": "https://www.kanaaksquare.com:5000",
            "http://localhost": "https://www.kanaaksquare.com",
          },
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.ok) {
            console.log("yes okay");
            response.blob().then((result) => {
              console.log("about to print");
              var outside = URL.createObjectURL(result);
              console.log(outside);
              //setModal(!modal)''

              let ShowToggle = this.state.ShowToggle;
              this.setState(
                () => ({
                  ShowToggle: true,
                }),
                console.log(this.state.ShowToggle)
              );
              document.getElementById("imgsrctxt").src = outside;
            });
          } //STATUS_OK
          else {
            throw new Error(response.status);
          }
        })
        .catch((error) => {
          console.log("error:" + error);
          let resmsg = this.state.resmsg;
          resmsg.error = "Error While registring the user";
          document.getElementById("failure").style.backgroundColor = "#f1926e";
          document.getElementById("success").style.backgroundColor = "#ffffff";
          resmsg.success = "";
          this.setState(
            () => ({
              resmsg: resmsg,
            }),
            console.log(this.state.resmsg)
          );
        });
    } //if
  }
  validateForm(userdetail) {
    let errors = this.state.errors;
    let formIsValid = true;

    if (!userdetail.username) {
      formIsValid = false;
      errors.username = "*Enter User Name";
    }

    if (!document.getElementById("mobile").value) {
      formIsValid = false;
      errors["mobile"] = "*Please enter your mobile no.";
    }

    if (typeof document.getElementById("mobile").value !== "undefined") {
      if (!document.getElementById("mobile").value.match(/^[0-9]{10}$/)) {
        formIsValid = false;
        errors["mobile"] = "*Please enter valid mobile no.";
      }
    }

    if (!document.getElementById("password").value) {
      formIsValid = false;
      errors["password"] = "*Enter password";
    }
    if (!document.getElementById("confirmpassword").value) {
      formIsValid = false;
      errors["confirmpassword"] = "*Enter Confirm Password";
    }

    if (
      typeof document.getElementById("password").value !== "undefined" &&
      typeof document.getElementById("confirmpassword").value !== "undefined"
    ) {
      if (
        document.getElementById("password").value !==
        document.getElementById("confirmpassword").value
      ) {
        formIsValid = false;
        errors["password"] = "*Password and Confirm Password doesn't match.";
      }
    }
    if (!document.getElementById("question").value) {
      formIsValid = false;
      errors["question"] = "*Select Secret Question";
    }

    if (!document.getElementById("answer").value) {
      formIsValid = false;
      errors["answer"] = "*Enter Sectert Answer";
    }

    this.setState(
      () => ({
        errors: errors,
      }),
      console.log(this.state.errors)
    );

    return formIsValid;
  }

  render() {
    var curr = new Date();
    curr.setDate(curr.getDate() + 3);
    var date = curr.toISOString().substr(0, 10);
    return (
      <div className="content">
        <Row>
          <Col md="8">
            {this.state.ShowToggle ? (
              <div id="modaldata">
                <Modal
                  isOpen={true}
                  modalTransition={{ timeout: 700 }}
                  backdropTransition={{ timeout: 1300 }}
                >
                  <ModalHeader>KSQUARE SECRET QR</ModalHeader>
                  <ModalBody>
                    <p class="success">User has been created Successfully!!!</p>
                    <p>
                      Scan the below QR code. This Will be used when you forgot
                      your password
                      <ol type="1">
                        <li>
                          Download Google Authenticator App. From
                          PlayStore/AppStore
                        </li>
                        <li>Click on the + symbol</li>
                        <li>Scan the Below QR code</li>
                      </ol>
                    </p>
                    <img src="" id="imgsrctxt" />
                  </ModalBody>
                  <ModalFooter>
                    <Button color="primary" onClick={this.handleClose}>
                      Close
                    </Button>
                  </ModalFooter>
                </Modal>
              </div>
            ) : null}
            <Card className="card-user">
              <CardHeader>
                <CardTitle tag="h5">New User Sign Up</CardTitle>
              </CardHeader>
              <CardBody>
                <Alert
                  color="success"
                  id="success"
                  style={{ "background-color": "#ffffff" }}
                >
                  {this.state.resmsg.success}
                </Alert>
                <Alert
                  color="danger"
                  id="failure"
                  style={{ "background-color": "#ffffff" }}
                >
                  {this.state.resmsg.error}
                </Alert>

                <Form name="signupfrm" id="signupfrm">
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <label>User Name</label>
                        <Input
                          defaultValue=""
                          placeholder="UserName"
                          type="text"
                          id="username"
                          name="username"
                          onChange={this.handleChange}
                          requiredText="Enter User Name"
                          required
                        />
                        <div className="text-danger">
                          {this.state.errors.username}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label>Password</label>
                        <Input
                          defaultValue=""
                          placeholder="Password"
                          type="password"
                          name="password"
                          id="password"
                          onChange={this.handleChange}
                        />
                        <div className="text-danger">
                          {this.state.errors.password}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <label>Confirm Password</label>
                        <Input
                          defaultValue=""
                          placeholder="Confirm Password"
                          type="password"
                          name="confirmpassword"
                          id="confirmpassword"
                        />
                        <div className="text-danger">
                          {this.state.errors.confirmpassword}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label for="mobile">Mobile Phone Number</Label>
                        <Input
                          defaultValue=""
                          placeholder="Phone Number"
                          type="number"
                          id="mobile"
                          name="mobile"
                          onChange={this.handleChange}
                        />
                        <div className="text-danger">
                          {this.state.errors.mobile}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label for="question">Secret Question</Label>
                        <Input
                          type="select"
                          name="question"
                          id="question"
                          onChange={this.handleChange}
                        >
                          <option>What is your Birth Time</option>
                          <option>What is your Father's Middle Name</option>
                          <option>What is your Mother's Maiden Name</option>
                          <option>What is the name of your First Friend</option>
                          <option>What is your Pet's Name</option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label for="answer">Answer</Label>
                        <Input
                          type="text"
                          name="answer"
                          id="answer"
                          onChange={this.handleChange}
                          placeholder="Secret Answer"
                        ></Input>
                        <div className="text-danger">
                          {this.state.errors.answer}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <div className="update ml-auto mr-auto">
                      <Button
                        className="btn-round"
                        color="primary"
                        type="submit"
                        onClick={this.handleSubmit}
                      >
                        SignUp
                      </Button>
                    </div>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    ); //Return
  } //Render
} //Class

export default SignUp;
