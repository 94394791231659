import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import React from "react";

import { userContext } from "../views/SignIn.jsx";
import { API_URL_PAYMENT, API_URL_FEES, ACCESS_ORGIN } from "./Constants";
import { Redirect } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  Input,
} from "reactstrap";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";

let main_dataarray = [
  {
    address: "40 4th",
    city: "Thanjavur",
    country: "India",
    county: "Tamilnadu",
    course: "Lower-EN",
    dob: 1589698823397,
    doj: 1589698823397,
    email: "piraiatsudan@gmail.com",
    ffname: "Ravanan",
    flname: "R",
    fname: "Pirai",
    lname: "R",
    mobile: "8097898945",
    payments: [
      {
        paymentid: "1",
        feebalance: " ",
        paymentdate: 1589698823397,
        amount: 0,
        studentidfk: " ",
      },
    ],
    qrpath: "/Users/maatharasikulandaivelu/D/tsms/qrcode/11104.png",
    qualification: "B Tech",
    searchString: "Pirai R 11104",
    studentid: 11104,
    timeslot: "10:00-11:00 AM",
    zip: "613006",
  },
];

const columns = [
  {
    dataField: "studentid",
    text: "Reg No",
    headerTitle: (column, colIndex) => `Registration Number`,
    editable: false,
  },
  {
    dataField: "fname",
    lname: "lname",
    text: "Name",
    headerTitle: (column, colIndex) => `First Name & Last Name`,
    editable: false,
    formatter: (cell, row) => {
      return (
        <p>
          {row.fname}&nbsp;{row.lname}
        </p>
      );
    },
  },

  {
    dataField: "studentenroll[0].timeslot",
    text: "Time Slot",
    headerTitle: true,
    editable: false,
  },
  {
    dataField: "studentenroll[0].course",
    text: "Course",
    headerTitle: true,
    editable: false,
  },
  {
    dataField: "mobile",
    text: "Mobile",
    headerTitle: (column, colIndex) => `Contact Number`,
    editable: false,
  },

  {
    dataField: "doj",
    text: "DOJ",
    headerTitle: (column, colIndex) => `Date of Joining`,
    formatter: (cell, row) => {
      const date1 = new Date(row.doj);
      console.log(date1);
      return (
        <p>
          {new Intl.DateTimeFormat("en-GB", {
            year: "numeric",
            month: "short",
            day: "2-digit",
          }).format(date1)}
        </p>
      );
    },
    editable: false,
  },

  {
    dataField: "payments[0].paymentdate",
    text: "Pay Date",
    headerTitle: (column, colIndex) => `Last Payment Date`,

    formatter: (cell, row) => {
      const date1 = new Date(row.payments[0].paymentdate);

      return (
        <p>
          {new Intl.DateTimeFormat("en-GB", {
            year: "numeric",
            month: "short",
            day: "2-digit",
          }).format(date1)}
        </p>
      );
    },
    editor: { type: Type.DATE },
  },
  {
    dataField: "payments[0].amount",
    text: "Amount",
    headerTitle: true,
    editor: {
      type: Type.SELECT,
      options: [
        {
          value: "4000",
          label: "4000",
        },
        {
          value: "3500",
          label: "3500",
        },
        {
          value: "1800",
          label: "1800",
        },
        {
          value: "2500",
          label: "2500",
        },
        {
          value: "2000",
          label: "2000",
        },
        {
          value: "450",
          label: "450",
        },
        {
          value: "300",
          label: "300",
        },
        {
          value: "150",
          label: "150",
        },
      ],
    },
  },
];

const selectRow = {
  mode: "checkbox",
  clickToSelect: true,
  clickToEdit: true,
};
let custom_array = [];

class FeePayment extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.state = {
      dataarray: main_dataarray,
      searchid: "",
      searchid: "",
      searchname: "",
      searchtime: "",
      searchmob: "",
      searchcourse: "",
      searchdoj: "",
    };
  }

  componentWillMount() {
    console.log("componenwilldMount*************");
    main_dataarray = [
      {
        studentid: 11102,
        fname: "Thiru",
        lname: "K",
        ffname: "Velu",
        flname: "N",
        qualification: "B Tech",
        dob: 957052800000,
        email: "thiru@gmail.com",
        course: "Lower-EN",
        mobile: "9790222259",
        doj: 1589760000000,
        timeslot: "7:00-8:00 AM",
        city: "Thanjavur",
        country: "India",
        county: "Karnataka",
        zip: "613006",
        address: "6, seethanagar",
        qrpath: "/Users/maatharasikulandaivelu/D/tsms/qrcode/11102.png",
        searchString: "Thiru K 11102",
        payments: [
          {
            paymentid: " ",
            feebalance: " ",
            paymentdate: 1589698823397,
            amount: 0.0,
            studentidfk: " ",
          },
        ],
      },
    ];

    this.setState(
      () => ({
        dataarray: main_dataarray,
      }),
      console.log(this.state.dataarray)
    );
    console.log("main_dataarray", main_dataarray);
  }
  handleChange(e) {
    console.log("calling filter function", e.target.value);
    console.log(main_dataarray);
    let elements = JSON.parse(JSON.stringify(main_dataarray));

    console.log("elements", elements);

    let filterstr = "";
    console.log(".............", elements);
    filterstr = e.target.value;
    custom_array = [];
    elements.map((key) => {
      key.map((dataItem, index) => {
        console.log("dataItem", dataItem);
        console.log("filterstr:: 1st-->", filterstr);
        console.log("KEY", dataItem.studentid);
        let findkey = "";
        if (e.target.name == "filid") {
          findkey = dataItem.studentid + "";
          this.setState({ searchid: e.target.value });
        } else if (e.target.name == "filname") {
          findkey = dataItem.fname + "";
          this.setState({ searchname: e.target.value });
        } else if (e.target.name == "filtime") {
          findkey = dataItem.timeslot + "";
          this.setState({ searchtime: e.target.value });
        } else if (e.target.name == "filcourse") {
          findkey = dataItem.course + "";
          this.setState({ searchcourse: e.target.value });
        } else if (e.target.name == "filmob") {
          findkey = dataItem.mobile + "";
          this.setState({ searchmob: e.target.value });
        } else if (e.target.name == "fildoj") {
          findkey = dataItem.doj + "";
          this.setState({ searchdoj: e.target.value });
        }
        console.log("findkey:: ", findkey);
        console.log("filterstr:: ", e.target.value);
        if (findkey.includes(e.target.value)) {
          //console.log("tru@@@@e");
          custom_array.push(dataItem);
        } else {
          //console.log("false");
        }
      }); //child map(map of map)
      //console.log("data value",selectedProduct);
    }); //parent map

    //custom_dataarray=

    this.setState(
      () => ({
        dataarray: custom_array,
      }),
      console.log(this.state.dataarray)
    );
    console.log("custom_array", custom_array);
  }

  handleGetCurrentData = () => {
    console.log("In handle submit method");
    let selArray = this.node.selectionContext.selected;
    let selarraystr = JSON.stringify(selArray);
    let wholedata = JSON.parse(JSON.stringify(this.node.table.props.data));
    let jsonArray = [];

    console.log("wholedata" + wholedata);

    Object.keys(wholedata).map((key) => {
      let sid = JSON.parse(JSON.stringify(wholedata[key]));
      if (selarraystr.indexOf(sid.studentid) > -1) {
        console.log("PAYDATE::", sid.payments[0].paymentdate);
        let datajson = {
          studentidfk: sid.studentid,
          paymentdate: sid.payments[0].paymentdate,
          amount: sid.payments[0].amount,
        };
        console.log("datajson:: " + JSON.stringify(datajson, null, " "));

        jsonArray.push(datajson);
      }
    });

    console.log(
      "this is the data" + JSON.parse(JSON.stringify(userContext.token))
    );
    let tokenkey = JSON.parse(JSON.stringify(userContext.token));
    let token = "Bearer " + tokenkey.token;
    console.log("jsonArray::----*****)", jsonArray);
    fetch(API_URL_PAYMENT, {
      method: "POST",
      body: JSON.stringify(jsonArray),
      headers: {
        authorization: token,
        Accept: "application/json",
        "Access-Control-Allow-Origin": "https://www.kanaaksquare.com:3000",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          /* response.json().then(result=>{
	           let studentDetails=JSON.parse(JSON.stringify(result));
	               console.log("Fee Payment Success"+result);   
	            });     */
          console.log("Payment Details added");
          return <Redirect to="/posts/" />;
        } else {
          throw new Error(response.code);
        }
      })
      .catch((error) => {
        console.log("Data Error:" + error);
      });
  };

  componentDidMount(event) {
    console.log("icomponentDidMount");
    let tokenkey = JSON.parse(JSON.stringify(userContext.token));
    let token = "Bearer " + tokenkey.token;
    console.log("token::----*****)", token);
    fetch(API_URL_FEES, {
      method: "GET",
      headers: {
        authorization: token,
        Accept: "application/json",
        "Access-Control-Allow-Origin": {
          "http://localhost:3000": "https://www.kanaaksquare.com:3000",
        },
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((result) => {
            let feedata = JSON.parse(JSON.stringify(result));
            console.log("ANSWER::--> ", JSON.parse(JSON.stringify(result)));
            main_dataarray = [];
            main_dataarray.push(feedata);
            console.log("main_dataarray", main_dataarray);
            console.log("studentDetails::--> ", feedata);
            this.setState(
              {
                dataarray: feedata,
              },
              console.log(this.state.dataarray)
            );
          });
          console.log("Value of data" + this.data);
        } else {
          throw new Error(response.code);
        }
      })
      .catch((error) => {
        console.log("Data Error:" + error);
      });
  }

  render() {
    return (
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-plain">
              <CardHeader>
                <CardTitle tag="h4">Fee Payments</CardTitle>
                <p className="card-category"></p>
              </CardHeader>
              <CardBody>
                <table class="react-bootstrap-table table table-bordered">
                  <tr>
                    <td></td>
                    <td>
                      <div class="col-xs-2">
                        <Input
                          id="filid"
                          name="filid"
                          type="test"
                          value={this.state.searchid}
                          onChange={this.handleChange}
                          style={{ width: "70px" }}
                        ></Input>
                      </div>
                    </td>
                    <td>
                      <div class="col-xs-2">
                        <Input
                          id="filname"
                          name="filname"
                          type="test"
                          value={this.state.searchname}
                          onChange={this.handleChange}
                          style={{ width: "70px" }}
                        ></Input>
                      </div>
                    </td>
                    <td>
                      <div class="col-xs-2">
                        <Input
                          id="filtime"
                          name="filtime"
                          type="test"
                          value={this.state.searchtime}
                          onChange={this.handleChange}
                          style={{ width: "70px" }}
                        ></Input>
                      </div>
                    </td>
                    <td>
                      <div class="col-xs-2">
                        <Input
                          id="filcourse"
                          name="filcourse"
                          type="test"
                          value={this.state.searchcourse}
                          onChange={this.handleChange}
                          style={{ width: "70px" }}
                        ></Input>
                      </div>
                    </td>
                    <td>
                      <div class="col-xs-2">
                        <Input
                          id="filmob"
                          name="filmob"
                          type="test"
                          value={this.state.searchmob}
                          onChange={this.handleChange}
                          style={{ width: "70px" }}
                        ></Input>
                      </div>
                    </td>
                    <td>
                      {" "}
                      <div class="col-xs-2">
                        <Input
                          id="fildoj"
                          name="fildoj"
                          type="test"
                          value={this.state.searchdoj}
                          onChange={this.handleChange}
                          style={{ width: "70px" }}
                        ></Input>
                      </div>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                </table>

                <BootstrapTable
                  ref={(n) => (this.node = n)}
                  id="feetbl"
                  keyField="studentid"
                  data={this.state.dataarray}
                  columns={columns}
                  cellEdit={cellEditFactory({
                    mode: "dbclick",
                    blurToSave: true,
                  })}
                  selectRow={selectRow}
                >
                  <table>
                    <tr>
                      <td>test</td>
                    </tr>
                  </table>
                </BootstrapTable>

                <div className="update ml-auto mr-auto">
                  <Button
                    className="btn-round"
                    color="primary"
                    type="submit"
                    onClick={this.handleGetCurrentData}
                  >
                    Pay Fees
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
export default FeePayment;
