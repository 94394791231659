import React from 'react'
import { Link, Route } from 'react-router-dom';

class Error extends React.Component
{
	render(){
		return(
			<div className="error-wrapper">
				User session is not valid please login again.
				<Route>
				<Link  to="/csc/login" >Click here to Login</Link>
				</Route>
				
			</div>
		);
	}
}
export default Error