/*!

=========================================================
* Student Registration - v1.1.0
=========================================================
* Copyright 2019 kanaa (http://www.kanaa.net)

@author: Maatharasi Kulandaivelu
@Since 27/11/2019
=========================================================



*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  Alert,
} from "reactstrap";
//import Table from "../variables/table.jsx";
import {
  API_URL_STUDENTS,
  API_URL_COURSELIST,
  ACCESS_ORGIN,
  API_URL_ENROLL,
  API_URL_FEES,
  API_URL_FEECONFIG,
  API_URL_STUDENTSLIST,
} from "./Constants";
import { userContext } from "../views/SignIn.jsx";
//import ScriptTag from 'react-script-tag';
import Dropzone from "react-dropzone";

//import {js} from "./drag.js" ;

class Administration extends React.Component {
  constructor(props) {
    super(props);
    
    this.handleChange = this.handleChange.bind(this);
	this.handleAdd = this.handleAdd.bind(this);
    this.onDrop = this.onDrop.bind(this);
	this.addFee=this.addFee.bind(this);
	this.addCourse=this.addCourse.bind(this);
    //this.onCancel=this.onCancel.bind(this);
    // this.state={result:[]};
    this.state = {
      dataarray: [],
      coursearray: [],
      file: null,
      fcontent: null,
      student: {
        studentid: "",
        name: "",
        enrolledon: "",
        timeslot: "",
        courseid: "",
      },
      errors: {
        fname: "",
        lname: "",
        ffname: "",
        flname: "",
        dob: "",
        qualification: "",
        email: "",
        mobile: "",
        address: "",
        zip: "",
        timeslot: "",
      },
      resmsg: { success: "", error: "" },
      resultdata: {},
    };
  }

  onDrop = (acceptedFiles) => {
    console.log(acceptedFiles);
    this.setState({
      file: URL.createObjectURL(acceptedFiles[0]),
    });
    this.setState({
      fcontent: acceptedFiles[0],
    });
  };

  handleChange(event) {
    let value = event.target.value;
    let name = event.target.name;
    this.setState(
      (prevState) => ({
        student: {
          ...prevState.student,
          [name]: value,
        },
      }),
      () => console.log(this.state.student)
    );
  }
  handleClearForm(e) {
    e.preventDefault();
    this.setState({
      student: {
        filedata: null,
        fname: "",
        lname: "",
        ffname: "",
        flname: "",
        dob: "",
        doj: "",
        qualification: "",
        email: "",
        mobile: "",
        course: "",
        address: "",
        city: "",
        county: "",
        country: "",
        zip: "",
      },
    });
  }


  handleAdd(event) {
    console.log("In handle add method");
    event.preventDefault();
	 {
      let coursename = document.getElementById("coursename").value;
      console.log(JSON.stringify(studentCopy));
      let tokenkey = JSON.parse(JSON.stringify(userContext.token));
      let token = "Bearer " + tokenkey.token;
      fetch(API_URL_ENROLL, {
        method: "POST",
        body: JSON.stringify(coursename),
        headers: {
          authorization: token,
          Accept: "application/json",
          "Content-type": "application/json",
          "Access-Control-Allow-Origin": ACCESS_ORGIN,
        },
      })
        .then((response) => {
          if (response.ok) {
            response.json().then((result) => {
              console.log("result::", response);
              document.getElementById("registerform").reset();
              let resmsg = this.state.resmsg;
              let studentDetails = JSON.parse(JSON.stringify(result));
              resmsg.success =
                "Course successfully registered for Student " +
                studentDetails.studentid +
                " with enrollment id " +
                studentDetails.enrollid;
              document.getElementById("success").style.backgroundColor =
                "#7ed6a5";
              document.getElementById("failure").style.backgroundColor =
                "#ffffff";
              resmsg.failure = "";

              this.setState(
                () => ({
                  resmsg: resmsg,
                }),
                console.log(this.state.resmsg)
              );
            });
          } //STATUS_OK
          else {
            throw new Error(response.status);
          }
        })
        .catch((error) => {
          console.log("error:" + error);
          let resmsg = this.state.resmsg;
          resmsg.error = "Error While registring the student";
          document.getElementById("failure").style.backgroundColor = "#f1926e";
          document.getElementById("success").style.backgroundColor = "#ffffff";
          resmsg.success = "";
          this.setState(
            () => ({
              resmsg: resmsg,
            }),
            console.log(this.state.resmsg)
          );
        });
    } //if
  }
  addFee(event) {
    console.log("In handle add fee method");
    event.preventDefault();
	 {
      let courseid = document.getElementById("courseid").value;
	  let feetype = document.getElementById("feetype").value;
	  let amt = document.getElementById("amount").value;
	  let jdata={"courseid":courseid,"feetype":feetype,"amount":amt};
	  alert("fee",feetype);
	  
	  console.log("JDATA"+JSON.stringify(jdata));
      
      let tokenkey = JSON.parse(JSON.stringify(userContext.token));
      let token = "Bearer " + tokenkey.token;
      fetch(API_URL_FEECONFIG, {
        method: "POST",
        body: JSON.stringify(jdata),
        headers: {
          authorization: token,
          Accept: "application/json",
          "Content-type": "application/json",
          "Access-Control-Allow-Origin": ACCESS_ORGIN,
        },
      })
        .then((response) => {
          if (response.ok) {
            response.json().then((result) => {
              console.log("result::", response);
              document.getElementById("feefrm").reset();
              let resmsg = this.state.resmsg;
              let feedetails = JSON.parse(JSON.stringify(result));
              resmsg.success =
                "Fee Configuration Successfully Added " +
                feedetails.feeid; 
              document.getElementById("success").style.backgroundColor =
                "#7ed6a5";
              document.getElementById("failure").style.backgroundColor =
                "#ffffff";
              resmsg.failure = "";

              this.setState(
                () => ({
                  resmsg: resmsg,
                }),
                console.log(this.state.resmsg)
              );
            });
          } //STATUS_OK
          else {
            throw new Error(response.status);
          }
        })
        .catch((error) => {
          console.log("error:" + error);
          let resmsg = this.state.resmsg;
          resmsg.error = "Error While configuring the fee";
          document.getElementById("failure").style.backgroundColor = "#f1926e";
          document.getElementById("success").style.backgroundColor = "#ffffff";
          resmsg.success = "";
          this.setState(
            () => ({
              resmsg: resmsg,
            }),
            console.log(this.state.resmsg)
          );
        });
    } //if
  }

  addCourse(event) {
    console.log("In Add course add method");
    event.preventDefault();
	 {
     let course = document.getElementById("coursename").value;
	  let duration=document.getElementById("duration").value;
    //  console.log(JSON.stringify(studentCopy));
		if(course== "" || duration== "")
		{
			let resmsg = this.state.resmsg;
			document.getElementById("success").style.backgroundColor =
                "#ffffff";
			document.getElementById("failure").style.backgroundColor =
                "#f1926e";
              resmsg.failure = "Enter coursename and duration";
			  resmsg.success="";
			  document.getElementById("failure").innerText="Enter coursename and duration";
			
              this.setState(
                () => ({
                  resmsg: resmsg,
                }),
                console.log(this.state.resmsg)
              );
            
		}else{
		let jdata={"coursename":course,"duration":duration}
      let tokenkey = JSON.parse(JSON.stringify(userContext.token));
      let token = "Bearer " + tokenkey.token;
      fetch(API_URL_COURSELIST, {
        method: "POST",
        body: JSON.stringify(jdata),
        headers: {
          authorization: token,
          Accept: "application/json",
          "Content-type": "application/json",
          "Access-Control-Allow-Origin": ACCESS_ORGIN,
        },
      })
        .then((response) => {
          if (response.ok) {
            response.json().then((result) => {
              console.log("result::", response);
              document.getElementById("courseform").reset();
              let resmsg = this.state.resmsg;
              let coursedetails = JSON.parse(JSON.stringify(result));
              resmsg.success =
                "Course successfully registered for Student " +
                coursedetails.coursename +
                " with courseid id " +
                coursedetails.courseid;
              document.getElementById("success").style.backgroundColor =
                "#7ed6a5";
              document.getElementById("failure").style.backgroundColor =
                "#ffffff";
              resmsg.failure = "";

              this.setState(
                () => ({
                  resmsg: resmsg,
                }),
                console.log(this.state.resmsg)
              );
            });
          } //STATUS_OK
          else {
            throw new Error(response.status);
          }
        })
        .catch((error) => {
          console.log("error:" + error);
          let resmsg = this.state.resmsg;
          resmsg.error = "Error While registring the student";
          document.getElementById("failure").style.backgroundColor = "#f1926e";
          document.getElementById("success").style.backgroundColor = "#ffffff";
          resmsg.success = "";
          this.setState(
            () => ({
              resmsg: resmsg,
            }),
            console.log(this.state.resmsg)
          );
        });
    } //if
	}//else	
  }

  
  componentDidMount() {
    let tokenkey = JSON.parse(JSON.stringify(userContext.token));
    let token = "Bearer " + tokenkey.token;
    console.log("token::----*****)", token);
    fetch(API_URL_STUDENTSLIST, {
      method: "GET",
      headers: {
        authorization: token,
        Accept: "application/json",
        "Access-Control-Allow-Origin": {
          "http://localhost:3000": "https://www.kanaaksquare.com:3000",
        },
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((result) => {
            let feedata = JSON.parse(JSON.stringify(result));
            console.log("ANSWER::--> ", JSON.parse(JSON.stringify(result)));
            console.log("studentDetails::--> ", feedata);
            this.setState(
              {
                dataarray: feedata,
              },
              console.log(this.state.dataarray)
            );
          });
          console.log("Value of data" + this.data);
        } else {
          throw new Error(response.code);
        }
      })
      .catch((error) => {
        console.log("Data Error:" + error);
      });

    fetch(API_URL_COURSELIST, {
      method: "GET",
      headers: {
        authorization: token,
        Accept: "application/json",
        "Access-Control-Allow-Origin": {
          "http://localhost:3000": "https://www.kanaaksquare.com:3000",
        },
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((result) => {
			console.log(result);
            let coursedata = JSON.parse(JSON.stringify(result));
			console.log("coursedata"+coursedata);
			const newToDoList = [...coursedata, {"courseid": 0, "coursename": 'Others'}]
			const newItem = { courseid: 0, coursename: 'Others' };
			
            this.setState(
              {
                coursearray: newToDoList,
              },
              console.log(this.state.coursearray)
            );
          });
		  
				
          console.log("Value of data" + this.data);
        } else {
          throw new Error(response.code);
        }
      })
      .catch((error) => {
        console.log("Data Error:" + error);
      });
  }

  render() {
    var curr = new Date();
    curr.setDate(curr.getDate() + 3);
    var date = curr.toISOString().substr(0, 10);
    return (
<div>	
    
	
	<div className="content">
        <Row>
          <Col md="8">
            <Card className="card-user">
              <CardHeader>
                <CardTitle tag="h5">Add Course</CardTitle>
              </CardHeader>
              <CardBody>
                <Alert
                  color="success"
                  id="success"
                  style={{ "background-color": "#ffffff" }}
                >
                  {this.state.resmsg.success}
                </Alert>
                <Alert
                  color="danger"
                  id="failure"
                  style={{ "background-color": "#ffffff" }}
                >
                  {this.state.resmsg.error}
                </Alert>

                <Form
                  name="courseform"
                  id="courseform"
                  encType="multipart/form-data"
                >
                  
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label for="course">Course Name</Label>
                        <Input
                          type="text"
                          name="coursename"
                          id="coursename"
                          required
						  
                        >
                          
                          
                        </Input>

						<Label for="duration">Duration in days</Label>
                        <Input
                          type="text"
                          name="duration"
                          id="duration"
						  required
						  
                          
                        >
                          
                          
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <div className="update ml-auto mr-auto">
                      <Button
                        className="btn-round"
                        color="primary"
                        type="submit"
                        onClick={this.addCourse}
                      >
                        Add Course
                      </Button>
                    </div>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
    </div>
					

	<div className="content">
        <Row>
          <Col md="8">
            <Card className="card-user">
              <CardHeader>
                <CardTitle tag="h5">Fee Configuration</CardTitle>
              </CardHeader>
              <CardBody>
                <Alert
                  color="success"
                  id="success"
                  style={{ "background-color": "#ffffff" }}
                >
                  {this.state.resmsg.success}
                </Alert>
                <Alert
                  color="danger"
                  id="failure"
                  style={{ "background-color": "#ffffff" }}
                >
                  {this.state.resmsg.error}
                </Alert>

                <Form
                  name="feefrm"
                  id="feefrm"
                  encType="multipart/form-data"
                >
                  
                 <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label for="course">Course</Label>
                        <Input
                          type="select"
                          name="courseid"
                          id="courseid"
                          onChange={this.handleChange}
                        >
                          
                          {this.state.coursearray.map((item, key) => (
                            <option value={item.courseid}>
                              {item.coursename}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
				  <Row>       
                   <Col>       
				   <FormGroup>
						<Label for="course">Fee Type</Label>
                        <Input
                          type="text"
                          name="feetype"
                          id="feetype"
                          
                        >
                          
                          
                        </Input>
						<Label for="amount">Amount</Label>
                        <Input
                          type="text"
                          name="amount"
                          id="amount"
                          
                        >
                          
                          
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <div className="update ml-auto mr-auto">
                      <Button
                        className="btn-round"
                        color="primary"
                        type="submit"
                        onClick={this.addFee}
                      >
                        Add Fee
                      </Button>
                    </div>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
    </div>
	
</div>

    ); //Return
  } //Render
} //Class
export default Administration;
