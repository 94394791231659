/*!

=========================================================
* Typing Student Management Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.kanaa.com/product/kanaa-dashboard-react
* Copyright 2019 kanaa (https://www.kanaa.com)

* Licensed under Kanaa

* Coded by kanaa

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create charts
import { Line, Pie } from "react-chartjs-2";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Button,
} from "reactstrap";
// core components
//import {
//  dashboard24HoursPerformanceChart,
//  dashboardEmailStatisticsChart,
//  dashboardNASDAQChart,
//} from "../variables/charts.jsx";
import { Chart } from "react-google-charts";
 
import XLSX from "xlsx";

import { make_cols } from "./MakeColumns";
import { userContext } from "../views/SignIn.jsx";
//import { SheetJSFT } from './types';

import {
  API_URL_ATTENDANCE,
  API_URL_PUNCH_ATTENDANCE,
  API_URL_COURSE_ATTENDANCE,
  API_URL_BATCH_ATTENDANCE,
  API_URL_HOLIDAY,
  ACCESS_ORGIN,
  API_URL_FEE_PENDING,
} from "./Constants";
import AttendanceRegistry from "./AttendanceRegistry.jsx";
//import test from "./test";
//import Chart from "chart.js";

const dtdata = [];
let hldata = [];
const attendancedata = [];
let feependata = [];
let resultdata = [];
let monthatten = [];
let courseatten = [];
let batchatten =[];
let curatten = [];

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [{ name: " ", studentid: " ", entry: "", actualslot: "" }],
      holidaylist: [{}],
      token: "",
      feedata: [],
      monthat: [["total",0]],
	  courseat:[["total",0]],
	  batchdata:[["total",0]],
    };

    //	this.handleScan = this.handleScan.bind(this);

    this.handleError = this.handleError.bind(this);
	
  }

  handleError(err) {
    console.error(err);
  }

  componentWillMount() {
    console.log(userContext.token);
    //this.setState({token: resultdata.token})
    //let token = userContext.token;
    let tokenkey = JSON.parse(JSON.stringify(userContext.token));
    let token = "Bearer " + tokenkey.token;
    //let token = 'Bearer ' + this.props.location.state.token

    fetch(API_URL_HOLIDAY, {
      method: "GET",
      headers: {
        authorization: token,
        Accept: "application/json",
        "Access-Control-Allow-Origin":ACCESS_ORGIN,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        console.log("RESPONSE::", response);

        if (response.ok) {
          response.json().then((result) => {
            let resultdata = JSON.parse(JSON.stringify(result));
            //console.log("Holiday data************" + JSON.stringify(result));
            // resultdata=result;
            //console.log("Attendance data" + resultdata);
            //resultdata.map(item,i)=>{ console.log("map::",item)});
            this.setState(
              () => ({
                holidaylist: resultdata,
              }),	console.log(this.state.holidaylist)
            );
          });
        } else {
          //this.props.history.push("/csc/error");
          throw new Error(response.code);
        }
      })
      .catch((error) => {
        console.log("Data Error:" + error);
      });

    //   fetch(API_URL_FEE_PENDING,
    //     {
    //       method:"GET",
    //       headers:{
    //       	authorization: token,
    //       	'Accept':"application/json",
    //           'Access-Control-Allow-Origin':{'http://localhost:3000':'https://www.kanaaksquare.com:3000'},
    //           'Content-Type':'application/json'
    //       }
    //     }
    //   ).then(response=>{
    // console.log(response)
    //     if(response.ok)
    //     {
    //       response.json().then(result=>{
    //            resultdata=JSON.parse(JSON.stringify(result));
    //                 console.log("Fee pending data"+JSON.stringify(resultdata));
    //              this.setState(()=>({
    //               feedata:resultdata
    //              }),console.log(this.state.feedata));
    //       });
    //       }else{
    // 		 //this.props.history.push("/csc/error");
    //           throw new Error(response.code)
    //          }
    //   }).catch((error)=>{
    //                       console.log('Data Error:'+error);
    //                     }
    //           );

               fetch(API_URL_PUNCH_ATTENDANCE,
                {
                  method:"GET",
                  headers:{
                    authorization: token,
                    'Accept':"application/json",
                      'Access-Control-Allow-Origin':ACCESS_ORGIN,
                      'Content-Type':'application/json'
                  }
                }
              ).then(response=>{
				
           // console.log("------>"+response);
		
                if(response.ok)
                {
                  response.json().then(result=>{
					   resultdata=JSON.parse(JSON.stringify(result));
                         this.setState(()=>({
                          monthat:resultdata
                         }),console.log(this.state.monthat));
						
                  });
                  }else{
                 //this.props.history.push("/csc/error");
                    //  throw new Error(response.code)
                     }
					 //console.log("%%%%%%%%%%");
					 console.log("########",this.state.monthat);
              }).catch((error)=>{
                                  console.log('Data Error:'+error);
                                }
                      );

	//****************COURSE ATTENDANCE ******************//
	fetch(API_URL_COURSE_ATTENDANCE,
		{
		  method:"GET",
		  headers:{
			authorization: token,
			'Accept':"application/json",
			  'Access-Control-Allow-Origin':ACCESS_ORGIN,
			  'Content-Type':'application/json'
		  }
		}
	  ).then(response=>{
		
   

		if(response.ok)
		{
		  response.json().then(result=>{
			   resultdata=JSON.parse(JSON.stringify(result));
				 this.setState(()=>({
					courseat:resultdata
				 }),console.log(this.state.courseat));
				
		  });
		  }else{
		
			  throw new Error(response.code)
			 }
			 
	  }).catch((error)=>{
						  console.log('Data Error:'+error);
						}
			  );
	//************BATCH ATTENDANCE****************/
	fetch(API_URL_BATCH_ATTENDANCE,
		{
		  method:"GET",
		  headers:{
			authorization: token,
			'Accept':"application/json",
			  'Access-Control-Allow-Origin':ACCESS_ORGIN,
			  'Content-Type':'application/json'
		  }
		}
	  ).then(response=>{
		
   

		if(response.ok)
		{
		  response.json().then(result=>{
			   resultdata=result;
			   console.log("RESULT@@@@@@",resultdata);
				 this.setState(()=>({
					batchdata:resultdata
				 }),console.log(this.state.batchdata));
				
		  });
		  }else{
		
			  throw new Error(response.code)
			 }
			 
	  }).catch((error)=>{
						  console.log('Data Error:'+error);
						}
			  );


       fetch(API_URL_ATTENDANCE,
         {
           method:"GET",
           headers:{
           	authorization: token,
           	'Accept':"application/json",
               'Access-Control-Allow-Origin':ACCESS_ORGIN,
               'Content-Type':'application/json'
           }
         }
       ).then(response=>{
     console.log(response)
         if(response.ok)
         {
           response.json().then(result=>{
                resultdata=JSON.parse(JSON.stringify(result));
                     //console.log("Attendance data"+JSON.stringify(resultdata));
                  this.setState(()=>({
                   data:resultdata
                  }),console.log(this.state.data));
           });
           }else{
     		 //this.props.history.push("/csc/error");
               throw new Error(response.code)
              }
       }).catch((error)=>{
                           console.log('Data Error:'+error);
                         }
               );
  }
  render() {
    const items = this.state.data.map((item, i) => {
      let da = JSON.parse(JSON.stringify(item));
      
      dtdata.push(
        <tr>
          <td>{da.name}</td>
          <td>{da.actualslot}</td>
          <td>{da.entry}</td>
        </tr>
      );
    });
    hldata = [];
    const holiitems = this.state.holidaylist.map((item, i) => {
		
	
      let hda = JSON.parse(JSON.stringify(item));
	  hldata.push(
        <tr>
          <td>{hda.festive}</td>
		  <td>{hda.festiveday}</td>
        </tr>
      );
    });

    feependata = [];
    //const feependatas= this.state.feedata.map((item,i) => {
    let da = JSON.parse(JSON.stringify(this.state.feedata));
    console.log("da", da);
    Object.keys(da).forEach(function (key) {
      console.log("DA", da[key]);
      feependata.push(
        <tr>
          <td>{key}</td>
          <td>{da[key]}</td>
        </tr>
      );
    });

   // monthatdata=[];
   	  
  //  const feependatas= this.state.feedata.map((item,i) => {
	console.log("*********");
 	 monthatten=[]; 
	courseatten=[];  
	batchatten=[];
	let test1=0;
	let att = JSON.parse(JSON.stringify(this.state.monthat));
    
	
	monthatten.push(
    att
     );
	let couatt = JSON.parse(JSON.stringify(this.state.courseat));
	
	courseatten.push(couatt); 
	
	//let batatt = JSON.parse(JSON.stringify(this.state.batchdata));
	//console.log("&&&&&&&",this.state.batchdata);
	
	batchatten.push(this.state.batchdata);
	 const valuesToAdd = [["Day", "Present"]];
	 const monthadata= monthatten[0]
	 const couadata =courseatten[0]
	 const batdata = batchatten
	 console.log("monthatten[0][1]",monthatten[0]);
	 console.log("&&&&&&&&-----");
	 let ctitle=[["Present","Course"],["Total",0]];
	 let example1NewArray=[];
	 let courseTodayAtten=[];
	 let batchAttenData=[];
	 
	 if(monthatten[0].length>0)
	 {
	 let totalintern=monthatten[0][0];	
	 
	 test1=totalintern[1];
	 monthadata.shift();
	 console.log("&&&&&&&&");
	  ctitle = [["Present","Course"],["Total",Number(test1)]]
	 }
	// const btitle =[["Student","Batch"],["Total",Number(test1)]];
	  example1NewArray = valuesToAdd.concat(monthadata);
	  courseTodayAtten = ctitle.concat(couadata);
	  batchAttenData = batdata;
	 console.log("********--->",batchatten);
	 
    //Object.keys(att).forEach(function (key) {
    //  console.log("DA", att[key]);
	//  let sid = JSON.parse(JSON.stringify(att[key]));
	//  console.log("STUDENTIDFKSID**---->>",sid.studentidfk)
	//  console.log("STUDENTIDFK---->>",att[key].studentidfk)
	//  console.log("NAME---->>",att[key].name)
    //  monthatten.push(
    //    <tr>
    //       {/*<td>{key}</td>*/}
	//	   {/*<td>TST</td>*/}
    //      <td>{att[key].studentidfk}</td>
	//	  <td>{att[key].name}</td>
	//	  <td>{att[key].entrytime}</td>
	//	  <td>{att[key].actualslot}</td>
    //    </tr>
    //    );
    //});

    //curatt=[];
    let catt = JSON.parse(JSON.stringify(this.state.data));
    console.log("catt", catt);
	//console.log(test1);
	//console.log("totalintern---->",totalintern);
	//console.log("totalintern",totalintern(0));
	//console.log("totalintern",totalintern[1]);
    Object.keys(catt).forEach(function (key) {
      console.log("Current", catt[key]);
      curatten.push(
        <tr>
          <td>{key}</td>
          <td>{catt[key]}</td>
        </tr>
      );
    });
	console.log("*********END*******");
    // });

    const previewStyle = {
      height: 240,
      width: 320,
    };
    return (
      <div className="content">
        <Row>
          <Col lg="4" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-time-alarm text-warning" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Attendance</p>
                      <table className="holiday-content">
                        <tbody>{monthatten}</tbody>
                      </table>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats">
                  {/*   <i className="fas fa-sync-alt" /> Update Now */}
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col lg="4" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-money-coins text-success" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Fee</p>
                      <CardTitle tag="p">$</CardTitle>
                      <table className="holiday-content">
                        <tbody>{feependata}</tbody>
                      </table>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats">
                  {/*  <i className="far fa-calendar" /> Last day */}
                </div>
              </CardFooter>
            </Card>
          </Col>

          <Col lg="4" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="2" xs="3">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-favourite-28 text-primary" />
                    </div>
                  </Col>
                  <Col md="10" xs="7">
                    <div className="numbers">
                      <p className="card-category">Up Coming Holidays</p>
                      <table className="holiday-content">
                        <tbody>{hldata}</tbody>
                      </table>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats">
                  {/* <i className="fas fa-sync-alt" /> Update now */}
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="8">
            <Card>
              <CardHeader>
                <CardTitle tag="h5">Attendance Details</CardTitle>
                <p className="card-category">Current Batch Attendance</p>
              </CardHeader>
              <CardBody></CardBody>
              <CardFooter>
                <div className="legend">
                  <i className="fa fa-circle text-primary" /> Present{" "}
                  <i className="fa fa-circle text-danger" /> Absent{" "}
                </div>
                <hr />
                <div className="stats">
                  {/* <i className="fa fa-calendar" /> */}
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>

        {/*<Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h5">Register Attendance</CardTitle>
              </CardHeader>
              <CardBody>
                <AttendanceRegistry />
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats">
                  <i className="fa fa-history" />
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>*/}
		
       
       
		<Row>
		<Col md="12">
            <Card className="card-chart">
              <CardHeader>
                <CardTitle tag="h5">Attendance</CardTitle>
                <p className="card-category">Overall Monthly Attendance</p>
              </CardHeader>
              <CardBody>
			  <Row>
			  <Col md="10">
			  <Chart
			width={'600px'}
			height={'300px'}
			chartType="LineChart"
			loader={<div>Loading Chart</div>}
			data={example1NewArray}
			options={{
				hAxis: {
					title: 'Day',
					
				},
				vAxis: {
					title: 'Present',
					maxValue:{test1},
					
					
				},
			}}
			rootProps={{ 'data-testid': '0' }}
			/>
			</Col>
			</Row>
                 
			   <Row>

			   <Col md="6">
				   <br></br>
                <p className="card-category">Today Attendance Course Wise</p>
           
			  <Chart
			width={'400px'}
			height={'300px'}
			chartType="PieChart"
			loader={<div>Loading Chart</div>}
			data={courseTodayAtten}
			options={{
				title: 'Today Attendance By Course',
				is3D: true,
			}}
			rootProps={{ 'data-testid': '1' }}
			/>
			</Col>
				<Col md="6">
                <p className="card-category">Current Batch Attendance</p>
				<Chart
				width={'500px'}
				height={'300px'}
				chartType="Bar"
				loader={<div>Loading Chart</div>}
				data={this.state.batchdata}
				options={{
					title: 'Current Batch Data',
					
				}}
				rootProps={{ 'data-testid': '1' }}
				/>
				</Col>
                  </Row>
              </CardBody>
			  
			  
			  
              <CardFooter>
                
              </CardFooter>
            </Card>

			
			
          </Col>
        </Row>
       


        <Row>
          <Col md="8">
            <Card className="card-chart">
              <CardHeader>
                <CardTitle tag="h5">Test Points</CardTitle>
                <p className="card-category">Line Chart with Points</p>
              </CardHeader>
              <CardBody>
                {/*<Line
                  data={dashboardNASDAQChart.data}
                  options={dashboardNASDAQChart.options}
                  width={400}
                  height={100}
                />*/}
              </CardBody>
              <CardFooter>
                <div className="chart-legend">
                  <i className="fa fa-circle text-info" /> Tesla Model S{" "}
                  <i className="fa fa-circle text-warning" /> BMW 5 Series
                </div>
                <hr />
                <div className="card-stats">
                  <i className="fa fa-check" /> Data information certified
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Dashboard;
