 /*!

=========================================================
* Typing Student Management Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.kanaa.com/product/kanaa-dashboard-react
* Copyright 2019 kanaa (https://www.kanaa.com)

* Licensed under Kanaa

* Coded by kanaa

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,  
  Row,
  Col,
  Button
} from "reactstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory  from 'react-bootstrap-table2-filter';
import {CSVLink, CSVDownload} from 'react-csv';
import {API_URL_FEES, ACCESS_ORGIN, API_URL_STUDENTS} from "./Constants";
//import cellEditFactory, {Type} from 'react-bootstrap-table2-editor';
//import paginationFactory from 'react-bootstrap-table2-paginator';
//{ textFilter, dateFilter }
import { userContext } from "../views/SignIn.jsx";

 const columns = [{
  dataField: 'studentid',
  text: 'Reg No',
  headerTitle:(column, colIndex) => `Registration Number`,
  //filter: textFilter(),

}, {
   
  dataField: 'fname',
  lname:'lname',  
  text: 'Name',
  headerTitle:(column, colIndex) => `First Name & Last Name`,

  //filter: textFilter(),
   formatter: (cell, row) => {
     return <p>{row.fname}&nbsp;{row.lname}</p>;
   }
},
 
 
{
  dataField: 'course',
  text: 'Course',
  headerTitle:true,
  //filter: textFilter(),
 
},
{
  dataField: 'mobile',
  text: 'Mobile',
  headerTitle:(column, colIndex) => `Contact Number`,
  
 
}

];


  const expcolumns = [{
  
  dataField:'enrollid',
  text: 'Enrollment ID',
  headerTitle:(column, colIndex) => `Enrollment ID`
  
},  
{
  dataField:'enrolledon',
  text: 'Enrolled On',  
  headerTitle:(column, colIndex) => `Enrolled On`,    
     formatter:(cell,row)=>{   
       let date1 = new Date();
              date1 = new Date(row.enrolledon);
              console.log("formated date@@@@@@", date1);
           return <p>{new Intl.DateTimeFormat('en-GB', { 
            year: 'numeric', 
            month: 'short',          
            day: '2-digit' 
          }).format(date1)}</p>
    } 
  
},
{
  dataField:'completedon',
  text: 'Completed On',
  headerTitle:(column, colIndex) => `Completed On`,    
     formatter:(cell,row)=>{   
       let date1 = new Date();
              date1 = new Date(row.completedon);
              console.log("formated date@@@@@@", date1);
           return <p>{new Intl.DateTimeFormat('en-GB', { 
            year: 'numeric', 
            month: 'short',          
            day: '2-digit' 
          }).format(date1)}</p>
  
},
},
{
  dataField:'coursedetails[0].courseid',
  text: 'Course ID',
  headerTitle:true, 
  hidden:true
},
{
  dataField:'coursedetails[0].coursename',
  text: 'Course Name',
  headerTitle:true, 
  hidden:true
},
{
  dataField:'studentidfk',
  text: 'Student',
  headerTitle:true, 
  hidden:true
}


];
  let childdata=[];
  let csvdata=[];


class EnrollmentDetails extends React.Component {
  
  constructor(props){
    super(props)
  this.state = { data: [], checkrow:[],exdata:[], expanded: [0, 1]}; 
 
  }

  
  


  componentDidMount()
  {
	  console.log("USER CONTEXT:: "+userContext.token);
	  let tokenkey=JSON.parse(JSON.stringify(userContext.token));
	  let token='Bearer ' +tokenkey.token;
	  fetch(API_URL_STUDENTS,{
      method:"GET",
      headers:{
    	  authorization: token,
    	  	Accept:"application/json",
            'Access-Control-Allow-Origin':{'http://localhost:3000':'http://kanaaksquare.com:3000'},
            'Content-Type':'application/json'
            	}
      }
      ).then(response=>{
        if(response.ok){
          response.json().then(result=>{
           let studentDetails=JSON.parse(JSON.stringify(result));
           console.log("Export Data*******"+(JSON.stringify(studentDetails)));
           let enrollobj=[];
                this.setState(()=>({
                        data: studentDetails                      
                      }), console.log(this.state.data));
                      
                let enrolldet=studentDetails.map((item, index) => {
                       if(item!=null){ 
                      let senrolldet =item.studentenroll.map((enroll,i)=>{
                        if(!(enroll.enrollid===null))
                        {
                         let jsonobj={"NAME":item.fname+" "+item.lname,"MOBILE":item.mobile}
                         csvdata.push(jsonobj); 
                         enrollobj.push(enroll);
                         console.log("Pays"+JSON.stringify(csvdata));
                        } 
                         return console.log(enroll);
                      })                      
                    }
                     return console.log(item);
              })                    
                 let enrollfinal=JSON.parse(JSON.stringify(enrollobj));
                 //console.log(payfinal);
                 childdata=enrollfinal;                            
                 this.setState(()=>({
                         exdata: enrollfinal                     
                      }), console.log(this.state.exdata));          
            });                
          
        }
        else{throw new Error(response.code)}

      }).catch((error)=>{
           console.log('Data Error:'+error);
      });
      
    
  }
   handleOnExpand = (row, isExpand, rowIndex, e) => {
     console.log("in handleon Expande method");
      }
      
  render() {
    
    const expandRow = {
      renderer: function(cell,row) {
        let i=0;
        
         console.log("DETAILST::"+JSON.stringify(childdata));
         const dtdata = [];
          const items= childdata.map((item,i) => {
             
              if(cell.studentid==item.studentid)
              {
               dtdata.push(<tr><td>{item.enrollid}</td><td>{new Date(item.enrolledon).toISOString()}</td><td>{item.courseid}</td><td>{item.coursedetails.coursename}</td><td>{item.timeslot}</td><td>{new Date(item.completedon).toISOString()}</td><td>{item.status}</td></tr>);
              }
               
             });
             
            
       /*  for(let k=0;k<1;k++)
        {
         if(cell.studentDetails===childdata[k].paymentid)
        {
         // childdata[k].paymentid=this.childdata[k].paymentid;
        //  childdata[k].studentidfk=this.childdata[k].studentidfk;
        console.log(":insid if");
        } 
        } */
        return(
       
        <div> 
          {/*  {(() => {
          const dtdata = [];

          for (let j = 0; j <= i; j++) {
           // dtdata.push(<td>{childdata[j].paymentid</td>);
           // dtdata.push(<td>{this.state.exdata[j].studentidfk}</td>);
          }

          return dtdata;
        })()} */}
            <table class="expanded"><thead><th>Enrollment Id</th><th>Enrolled On</th><th>Course Id</th><th>Course Name</th><th>Timeslot</th><th>Completed On</th><th>Status</th></thead><tbody>{dtdata}</tbody></table>
        </div>
        )
      }
      
    };
    return (
         <div className="content">
          <Row>
            <Col md="12">
              <Card className="card-plain">
                <CardHeader>
                  <CardTitle tag="h4">Payment Details</CardTitle>
                  <p>
                    Full Payment details of all the studends. 
                    Export Entire Payment Details of all students
                  </p>
                   <CSVLink color="primary"  className="btn btn-round btn-primary" data={csvdata} filename={"Ksquare_typing_payment.csv"}>
                    Export CSV</CSVLink>
                </CardHeader>
                <CardBody>
                      <BootstrapTable  
                      classes="customdesign"
                    id='studentid' 
                    keyField='studentid' 
                    data={ this.state.data } 
                    columns={ columns }    
                     
                      expandRow={ expandRow }
                    table-responsive>                    
                    </BootstrapTable>
                  
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      
    );
  }
}

export default EnrollmentDetails;