import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import React from "react";
import { Dots, Bounce } from "react-activity";
import "react-activity/dist/react-activity.css";
import { userContext } from "../views/SignIn.jsx";
import Backdrop from "@material-ui/core/Backdrop";
// import CircularProgress from "@material-ui/core/CircularProgress";
import {API_URL_COMPLETE, API_URL_STUDENTSLIST,API_URL_STUDENTS,  ACCESS_ORGIN, API_URL_ENROLL } from "./Constants";
import { Redirect } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  Input,
} from "reactstrap";
import Modal from "@material-ui/core/Modal";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";

let main_dataarray = [
  {
    studentid:111,
    fname: "TEST",
    lname: "TEST",
    enrollid:111,
    courseid:100,
    coursename:"TEST",
    enrolledon:"",
   


  
    
  },
];

const columns = [
  {
    dataField: "studentid",
    text: "Reg No",
    headerTitle: (column, colIndex) => `Registration Number`,
    editable: false,
  },
  {
    dataField: "fname",
    lname: "lname",
    text: "Name",
    headerTitle: (column, colIndex) => `First Name & Last Name`,
    editable: false,
    formatter: (cell, row) => {
      return (
        <p>
          {row.fname}&nbsp;{row.lname}
        </p>
      );
    },
  },

  {
    dataField: "enrollid",
    text: "Enroll ID",
    headerTitle: true,
    editable: false,
  },
  {
    dataField: "enrolledon",
    text: "Joined On",
    headerTitle: true,
    editable: false,
  },
  {
    dataField: "coursename",
    text: "Course Name",
    headerTitle: true,
    editable: false,
  },
  

  
];

const selectRow = {
  mode: "checkbox",
  clickToSelect: true,
  clickToEdit: true,
};
let custom_array = [];

class Purge extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.loaddata = this.loaddata.bind(this);
    this.state = {
      isLoaded: true,
      dataarray: main_dataarray,
      searchid: "",
      searchname: "",
      searchenrollid: "",
      searchenrolledon: "",
      searchcourse: "",
      
    };
  }

  componentWillMount() {
    console.log("componenwilldMount*************");
    main_dataarray = [
      {
        studentid:111,
    fname: "TEST",
    lname: "TEST",
    enrollid:111,
    courseid:100,
    coursename:"TEST",
    enrolledon:"",
        
      },
    ];

    this.setState(
      () => ({
        dataarray: main_dataarray,
      }),
      console.log(this.state.dataarray)
    );
    console.log("main_dataarray", main_dataarray);
  }
  handleChange(e) {
    console.log("calling filter function", e.target.value);
    console.log(main_dataarray);
    let elements = JSON.parse(JSON.stringify(main_dataarray));

    console.log("elements", elements);

    let filterstr = "";
    console.log(".............", elements);
    filterstr = e.target.value;
    custom_array = [];
    elements.map((key) => {
      key.map((dataItem, index) => {
        console.log("dataItem", dataItem);
        console.log("filterstr:: 1st-->", filterstr);
        console.log("KEY", dataItem.studentid);
        let findkey = "";
        if (e.target.name == "filid") {
          findkey = dataItem.studentid + "";
          this.setState({ searchid: e.target.value });
        } else if (e.target.name == "filname") {
          findkey = dataItem.fname + "";
          this.setState({ searchname: e.target.value });
        } else if (e.target.name == "filenrollid") {
            findkey = dataItem.enrollid + "";
            this.setState({ searchenrollid: e.target.value });
        }else if (e.target.name == "filenrolledon") {
          findkey = dataItem.enrolledon + "";
          this.setState({ searchenrolledon: e.target.value });
        } else if (e.target.name == "filcourse") {
          findkey = dataItem.coursename + "";
          this.setState({ searchcourse: e.target.value });
        
        } 
        console.log("findkey:: ", findkey);
        console.log("filterstr:: ", e.target.value);
        if (findkey.includes(e.target.value)) {
          //console.log("tru@@@@e");
          custom_array.push(dataItem);
        } else {
          //console.log("false");
        }
      }); //child map(map of map)
      //console.log("data value",selectedProduct);
    }); //parent map

    //custom_dataarray=

    this.setState(
      () => ({
        dataarray: custom_array,
      }),
      console.log(this.state.dataarray)
    );
    console.log("custom_array", custom_array);
  }

  handleGetCurrentData = () => {
    this.setState({ isLoaded: false });
    console.log("In handle submit method");
    let selArray = this.node.selectionContext.selected;
    let selarraystr = JSON.stringify(selArray);
    let wholedata = JSON.parse(JSON.stringify(this.node.table.props.data));
    let jsonArray = [];

    console.log("wholedata" + wholedata);
    let enroll = [];
    let enrollid = 0;
    Object.keys(wholedata).map((key) => {
      let sid = JSON.parse(JSON.stringify(wholedata[key]));

      if (selarraystr.indexOf(sid.studentid) > -1) {
        if (enrollid==0)
        {
          enrollid =  sid.enrollid ;
        }else{
        enrollid = enrollid + "," + sid.enrollid ;
        }
        let datajson = {
          enrollid: sid.enrollid,
        };
        console.log("ENROLLID:: ",enrollid);
        console.log("datajson:: " + JSON.stringify(datajson, null, " "));
        jsonArray.push(datajson);
      }
    });
    
    console.log(
      "this is the data" + JSON.parse(JSON.stringify(userContext.token))
    );
    let tokenkey = JSON.parse(JSON.stringify(userContext.token));
    let token = "Bearer " + tokenkey.token;
    console.log("jsonArray::----*****)", jsonArray);
    //student = [1, 2];
    console.log("ids:: ", enrollid);
    //fetch(API_URL_STUDENTS + "/" + studentid, {
    fetch(API_URL_ENROLL + "/" + enrollid, {
      method: "PATCH",
      // body: JSON.stringify(jsonArray),
      // headers.add("Access-Control-Allow-Methods", "GET, POST, OPTIONS, PUT, DELETE");
      headers: {
        "Access-Control-Allow-Headers":
          "Origin, X-Requested-With, Content-Type, Accept",
        "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, DELETE",
        authorization: token,
        "Content-Type": "text/plain",
        "Access-Control-Allow-Origin": ACCESS_ORGIN,
      },
    })
      .then((response) => {
        if (response.ok) {
          /* response.json().then(result=>{
	           let studentDetails=JSON.parse(JSON.stringify(result));
	               console.log("Fee Payment Success"+result);   
	            });     */
          console.log("Payment Details added");
          this.loaddata();
          // return <Redirect to="/posts/" />;
        } else {
          throw new Error(response.code);
        }
      })
      .catch((error) => {
        console.log("Data Error:" + error);
      });
    this.setState({ isLoaded: true });
  };

  loaddata() {
    // let tokenkey = JSON.parse(JSON.stringify(userContext.token));
    // let token = "Bearer " + tokenkey.token;
    // console.log("token::----*****)", token);
    // fetch(API_URL_ENROLL, {
    //   method: "GET",
    //   headers: {
    //     authorization: token,
    //     Accept: "application/json",
    //     "Access-Control-Allow-Origin": {
    //       "http://localhost:3000": "https://www.kanaaksquare.com:3000",
    //     },
    //     "Content-Type": "application/json",
    //   },
    // })
    //   .then((response) => {
    //     if (response.ok) {
    //       response.json().then((result) => {
    //         let feedata = JSON.parse(JSON.stringify(result));
    //         console.log("ANSWER::--> ", JSON.parse(JSON.stringify(result)));
    //         main_dataarray = [];
    //         main_dataarray.push(feedata);
    //         console.log("main_dataarray", main_dataarray);
    //         console.log("studentDetails::--> ", feedata);
    //         this.setState(
    //           {
    //             dataarray: feedata,
    //           },
    //           console.log(this.state.dataarray)
    //         );
    //       });
    //       console.log("Value of data" + this.data);
    //     } else {
    //       throw new Error(response.code);
    //     }
    //   })
    //   .catch((error) => {
    //     console.log("Data Error:" + error);
    //   });
    console.log("icomponentDidMount");
    let tokenkey = JSON.parse(JSON.stringify(userContext.token));
    let token = "Bearer " + tokenkey.token;
    console.log("token::----*****)", token);
    fetch(API_URL_COMPLETE, {
      method: "GET",
      headers: {
        authorization: token,
        Accept: "application/json",
        "Access-Control-Allow-Origin": {
          "http://localhost:3000": "https://www.kanaaksquare.com:3000",
        },
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((result) => {
            let enrolldata = JSON.parse(JSON.stringify(result));
            console.log("ANSWER::--> ", JSON.parse(JSON.stringify(result)));
            main_dataarray = [];
            main_dataarray.push(enrolldata);
            console.log("main_dataarray", main_dataarray);
            console.log("studentDetails::--> ", enrolldata);
            this.setState(
              {
                dataarray: enrolldata,
              },
              console.log(this.state.dataarray)
            );
          });
          console.log("Value of data" + this.data);
        } else {
          throw new Error(response.code);
        }
      })
      .catch((error) => {
        console.log("Data Error:" + error);
      });
  }

  componentDidMount(event) {
    console.log("icomponentDidMount");
    let tokenkey = JSON.parse(JSON.stringify(userContext.token));
    let token = "Bearer " + tokenkey.token;
    console.log("token::----*****)", token);
    fetch(API_URL_COMPLETE, {
      method: "GET",
      headers: {
        authorization: token,
        Accept: "application/json",
        "Access-Control-Allow-Origin": {
          "http://localhost:3000": "https://www.kanaaksquare.com:3000",
        },
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((result) => {
            let enrolldata = JSON.parse(JSON.stringify(result));
            console.log("ANSWER::--> ", JSON.parse(JSON.stringify(result)));
            main_dataarray = [];
            main_dataarray.push(enrolldata);
            console.log("main_dataarray", main_dataarray);
            console.log("studentDetails::--> ", enrolldata);
            this.setState(
              {
                dataarray: enrolldata,
              },
              console.log(this.state.dataarray)
            );
          });
          console.log("Value of data" + this.data);
        } else {
          throw new Error(response.code);
        }
      })
      .catch((error) => {
        console.log("Data Error:" + error);
      });
  }

  render() {
    return (
      <div className="content">
        {this.state.isLoaded ? (
          <label></label>
        ) : (
          <Modal
            open={true}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <Dots color="#727981" size={32} speed={1} animating={true} />
          </Modal>
        )}
        <Row>
          <Col md="12">
            <Card className="card-plain">
              <CardHeader>
                <CardTitle tag="h4">Student List</CardTitle>

                <p className="card-category"></p>
              </CardHeader>
              <CardBody>
                <table class="react-bootstrap-table table table-bordered">
                  <tr>
                    <td></td>
                    <td>
                      <div class="col-xs-2">
                        <Input
                          id="filid"
                          name="filid"
                          type="test"
                          value={this.state.searchid}
                          onChange={this.handleChange}
                          style={{ width: "70px" }}
                        ></Input>
                      </div>
                    </td>
                    <td>
                      <div class="col-xs-2">
                        <Input
                          id="filname"
                          name="filname"
                          type="test"
                          value={this.state.searchname}
                          onChange={this.handleChange}
                          style={{ width: "70px" }}
                        ></Input>
                      </div>
                    </td>
                    <td>
                      <div class="col-xs-2">
                        <Input
                          id="filenrollid"
                          name="filenrollid"
                          type="test"
                          value={this.state.searchenrollid}
                          onChange={this.handleChange}
                          style={{ width: "70px" }}
                        ></Input>
                      </div>
                    </td>
                    <td>
                      {" "}
                      <div class="col-xs-2">
                        <Input
                          id="filenrolledon"
                          name="filenrolledon"
                          type="test"
                          value={this.state.searchenrolledon}
                          onChange={this.handleChange}
                          style={{ width: "70px" }}
                        ></Input>
                      </div>
                    </td>
                    <td>
                      <div class="col-xs-2">
                        <Input
                          id="filcourse"
                          name="filcourse"
                          type="test"
                          value={this.state.searchcourse}
                          onChange={this.handleChange}
                          style={{ width: "70px" }}
                        ></Input>
                      </div>
                    </td>
                   
                    
                    
                  </tr>
                </table>

                <BootstrapTable
                  ref={(n) => (this.node = n)}
                  id="feetbl"
                  keyField="studentid"
                  data={this.state.dataarray}
                  columns={columns}
                  cellEdit={cellEditFactory({
                    mode: "dbclick",
                    blurToSave: true,
                  })}
                  selectRow={selectRow}
                >
                  <table>
                    <tr>
                      <td>test</td>
                    </tr>
                  </table>
                </BootstrapTable>

                <div className="update ml-auto mr-auto">
                  <Button
                    className="btn-round"
                    color="primary"
                    type="submit"
                    onClick={this.handleGetCurrentData}
                  >
                     COMPLETE
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
export default Purge;
