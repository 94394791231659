/*!

=========================================================
* Typing Student Management Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.kanaa.com/product/kanaa-dashboard-react
* Copyright 2019 kanaa (https://www.kanaa.com)

* Licensed under Kanaa

* Coded by kanaa

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "./views/Dashboard.jsx";
//import Notifications from "views/Notifications.jsx";
import UserPage from "./views/User.jsx";
import Registration from "./views/Registration.jsx";
import ChangePassword from "./views/ChangePassword.jsx";
import ViewUsers from "./views/ViewUsers.jsx";
import Icons from "./views/Icons.jsx";
import Typography from "./views/Typography.jsx";
import PaymentDetails from "./views/ViewPaymentDetails.jsx";
import FeePayment from "./views/FeePayment.jsx";
import EnrollCourse from "./views/EnrollCourse.jsx";
import EnrollmentDetails from "./views/EnrollmentDetails.jsx";
import Purge from "./views/Purge.jsx";
//import Attendance from "./views/Attendance.jsx";
//import { default as Test } from "views/test.jsx";
import SignUp from "./views/SignUp.jsx";
import SignIn from "./views/SignIn.jsx";
import Error from "./views/Error.jsx";
import Administration from "./views/Administration.jsx"

var routes = [
  /*  {
    path: "/test",
    name: "Attendance",
    icon: "nc-icon nc-bank",
    component: Attendance,
    layout: "/admin"
  }, */

  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-bank",
    component: Dashboard,
    layout: "/admin",
  },

  /*{
    path: "/notifications",
    name: "Notifications",
    icon: "nc-icon nc-bell-55",
    component: Notifications,
    layout: "/admin"
  },*/
  {
    path: "/user-page",
    name: "User Profile",
    icon: "nc-icon nc-single-02",
    component: UserPage,
    layout: "/admin",
  },
  {
    path: "/registration",
    name: "Registration",
    icon: "nc-icon nc-tap-01",
    component: Registration,
    layout: "/admin",
  },
  {
    path: "/feepayment",
    name: "Pay Fee",
    icon: "nc-icon nc-money-coins",
    component: FeePayment,
    layout: "/admin",
  },
  {
    path: "/remove",
    name: "Remove",
    icon: "nc-icon nc-money-coins",
    component: Purge,
    layout: "/admin",
  },
  {
    path: "/paymentdetails",
    name: "View Payment Details",
    icon: "nc-icon nc-tile-56",
    component: PaymentDetails,
    layout: "/admin",
  },
  {
    path: "/enroll",
    name: "Course Enrollment",
    icon: "nc-icon nc-bullet-list-67",
    component: EnrollCourse,
    layout: "/admin",
  },
  {
    path: "/enrolldetails",
    name: "Enrollment Details",
    icon: "nc-icon nc-simple-add",
    component: EnrollmentDetails,
    layout: "/admin",
  },

  {
    path: "/changepwd",
    name: "Change Password",
    icon: "nc-icon nc-settings",
    component: ChangePassword,
    layout: "/admin",
  },
  {
    path: "/signup",
    name: "SignUp",
    icon: "nc-icon nc-settings",
    component: SignUp,
    layout: "/csc",
  },
  {
    path: "/login",
    name: "SignIn",
    icon: "nc-icon nc-settings",
    component: SignIn,
    layout: "/csc",
  },
  {
    path: "/error",
    name: "Error",
    icon: "nc-icon nc-settings",
    component: Error,
    layout: "/csc",
  },

  {
    path: "/viewusers",
    name: "Studends Details",
    icon: "nc-icon nc-layout-11",
    component: ViewUsers,
    layout: "/admin",
  },
  {
    path: "/remove",
    name: "Mark Course Complete",
    icon: "nc-icon nc-satisfied",
    component: Purge,
    layout: "/admin",
  },
  {
    path: "/config",
    name: "Admin Settings",
    icon: "nc-icon nc-satisfied",
    component: Administration,
    layout: "/admin",
  },
  
];
export default routes;
