/*!

=========================================================
* Typing Student Management Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.kanaa.com/product/kanaa-dashboard-react
* Copyright 2019 kanaa (https://www.kanaa.com)

* Licensed under Kanaa

* Coded by kanaa

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Input,
  FormGroup,
  Form,
  Button,
  UncontrolledAlert,
} from "reactstrap";
import { ACCESS_ORGIN, API_URL_CHANGEPWD } from "./Constants";
import { userContext } from "../views/SignIn.jsx";

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userprofile: { username: "", password: "" },
      resmsg: { success: "", error: "" },
      userpwd: { newpassword: "", confirmpassword: "" },
      showResult: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleSubmit() {
    console.log("inside handlesubmit");
    if (this.isValidform) {
      console.log("inside isvalide true", JSON.stringify(userContext.token));
      let tokenkey = JSON.parse(JSON.stringify(userContext.token));
      let username = JSON.parse(JSON.stringify(userContext.username));
      let token = "Bearer " + tokenkey.token;
      let jsonArray = {
        username: username,
        password: this.state.userpwd.newpassword,
      };
      console.log("JSON.stringify(jsonArray)", JSON.stringify(jsonArray));
      fetch(API_URL_CHANGEPWD, {
        method: "POST",
        body: JSON.stringify(jsonArray),
        headers: {
          authorization: token,
          Accept: "application/json",
          "Access-Control-Allow-Origin": "https://www.kanaaksquare.com:3000",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.ok) {
            response.json().then((result) => {
              let resultdetails = JSON.parse(JSON.stringify(result));
              console.log("Fee Payment Success" + resultdetails.result);
              let resmsg = this.state.resmsg;
              resmsg.success = resultdetails.result;
              this.setState({ resmsg: resmsg });
            });
          } else {
            throw new Error(response.code);
          }
        })
        .catch((error) => {
          console.log("Data Error:" + error);
        });
    } //if
  }

  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;
    this.setState(
      (prevState) => ({
        userpwd: {
          ...prevState.userpwd,
          [name]: value,
        },
      }),
      () => console.log(this.state.userpwd)
    );
  }

  isValidform() {
    let isvalid = false;
    let resmsg = this.state.resmsg;
    let errors = this.state.errors.npwd;
    if (
      this.state.user.newpassword === "" ||
      this.state.user.newpassword === " "
    ) {
      resmsg.error = "*Enter Password";
      isvalid = false;
      this.setState({ resmsg: resmsg }, console.log(this.state.resmsg));
    } else if (
      this.state.user.newpassword === this.state.user.confirmpassword
    ) {
      isvalid = true;
    } else {
      isvalid = false;
      resmsg.error = "Password doesnot match";
      this.setState({ resmsg: resmsg }, console.log(this.state.resmsg));
    }
  }

  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h5">Update Password</CardTitle>
                  <div className="text-primary">
                    {this.state.resmsg.success}
                  </div>
                </CardHeader>
                <CardBody>
                  <div>
                    <Row>
                      <Col md="8">
                        <FormGroup>
                          <label>New Password</label>
                          <Input
                            defaultValue=""
                            placeholder="new password"
                            type="password"
                            id="newpassword"
                            name="newpassword"
                            onChange={this.handleChange}
                            requiredText="Password is not valid"
                            required
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="8">
                        <FormGroup>
                          <label>Confirm Password</label>
                          <Input
                            defaultValue=""
                            placeholder="confirm password"
                            type="password"
                            id="confirmpassword"
                            name="confirmpassword"
                            onChange={this.handleChange}
                            requiredText="Password is not valid"
                            required
                          />
                          <div className="text-danger">
                            {this.state.resmsg.errors}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <div className="update ml-auto mr-auto">
                        <Button
                          className="btn-round"
                          color="primary"
                          type="submit"
                          onClick={this.handleSubmit}
                        >
                          Update Password
                        </Button>
                      </div>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default ChangePassword;
