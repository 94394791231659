/*!

=========================================================
* Student Registration - v1.1.0
=========================================================
* Copyright 2019 kanaa (http://www.kanaa.net)

@author: Maatharasi Kulandaivelu
@Since 27/11/2019
=========================================================



*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  Alert,
} from "reactstrap";
//import Table from "../variables/table.jsx";
import {
  API_URL_STUDENTS,
  API_URL_COURSELIST,
  ACCESS_ORGIN,
  API_URL_ENROLL,
  API_URL_FEES,
  API_URL_STUDENTSLIST,
} from "./Constants";
import { userContext } from "../views/SignIn.jsx";
//import ScriptTag from 'react-script-tag';
import Dropzone from "react-dropzone";

//import {js} from "./drag.js" ;

class EnrollCourse extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onDrop = this.onDrop.bind(this);
    //this.onCancel=this.onCancel.bind(this);
    // this.state={result:[]};
    this.state = {
      dataarray: [],
      coursearray: [],
      file: null,
      fcontent: null,
      student: {
        studentid: "",
        name: "",
        enrolledon: "",
        timeslot: "",
        courseid: "",
      },
      errors: {
        fname: "",
        lname: "",
        ffname: "",
        flname: "",
        dob: "",
        qualification: "",
        email: "",
        mobile: "",
        address: "",
        zip: "",
        timeslot: "",
      },
      resmsg: { success: "", error: "" },
      resultdata: {},
    };
  }

  onDrop = (acceptedFiles) => {
    console.log(acceptedFiles);
    this.setState({
      file: URL.createObjectURL(acceptedFiles[0]),
    });
    this.setState({
      fcontent: acceptedFiles[0],
    });
  };

  handleChange(event) {
    let value = event.target.value;
    let name = event.target.name;
    this.setState(
      (prevState) => ({
        student: {
          ...prevState.student,
          [name]: value,
        },
      }),
      () => console.log(this.state.student)
    );
  }
  handleClearForm(e) {
    e.preventDefault();
    this.setState({
      student: {
        filedata: null,
        fname: "",
        lname: "",
        ffname: "",
        flname: "",
        dob: "",
        doj: "",
        qualification: "",
        email: "",
        mobile: "",
        course: "",
        address: "",
        city: "",
        county: "",
        country: "",
        zip: "",
      },
    });
  }

  handleSubmit(event) {
    console.log("In handle submit method");
    event.preventDefault();

    // if (this.validateForm(this.state.student))
    {
      let studentCopy = JSON.parse(JSON.stringify(this.state.student));
      //make changes to studentcopy //When user choose the default value handleChange event will not triggered

      studentCopy.enrolledon = document.getElementById("enrolledon").value;
      studentCopy.courseid = document.getElementById("courseid").value;
      studentCopy.timeslot = document.getElementById("timeslot").value;
      studentCopy.studentid = document.getElementById("studentid").value;

      console.log(JSON.stringify(studentCopy));
      let tokenkey = JSON.parse(JSON.stringify(userContext.token));
      let token = "Bearer " + tokenkey.token;
      console.log(JSON.stringify(studentCopy));

      // const formData = new FormData();
      // formData.append(
      //   "json",
      //   new Blob([JSON.stringify(studentCopy)], { type: "application/json" })
      // );

      fetch(API_URL_ENROLL, {
        method: "POST",
        body: JSON.stringify(studentCopy),
        headers: {
          authorization: token,
          Accept: "application/json",
          "Content-type": "application/json",
          "Access-Control-Allow-Origin": "https://www.kanaaksquare.com:3000",
        },
      })
        .then((response) => {
          if (response.ok) {
            response.json().then((result) => {
              console.log("result::", response);
              document.getElementById("registerform").reset();
              let resmsg = this.state.resmsg;
              let studentDetails = JSON.parse(JSON.stringify(result));
              resmsg.success =
                "Course successfully registered for Student " +
                studentDetails.studentid +
                " with enrollment id " +
                studentDetails.enrollid;
              document.getElementById("success").style.backgroundColor =
                "#7ed6a5";
              document.getElementById("failure").style.backgroundColor =
                "#ffffff";
              resmsg.failure = "";

              this.setState(
                () => ({
                  resmsg: resmsg,
                }),
                console.log(this.state.resmsg)
              );
            });
          } //STATUS_OK
          else {
            throw new Error(response.status);
          }
        })
        .catch((error) => {
          console.log("error:" + error);
          let resmsg = this.state.resmsg;
          resmsg.error = "Error While registring the student";
          document.getElementById("failure").style.backgroundColor = "#f1926e";
          document.getElementById("success").style.backgroundColor = "#ffffff";
          resmsg.success = "";
          this.setState(
            () => ({
              resmsg: resmsg,
            }),
            console.log(this.state.resmsg)
          );
        });
    } //if
  }
  validateForm(studentdetail) {
    let errors = this.state.errors;
    let formIsValid = true;

    if (!studentdetail.fname) {
      formIsValid = false;
      errors.fname = "*Enter First Name";
    }
    if (typeof document.getElementById("fname").value !== "undefined") {
      if (!document.getElementById("fname").value.match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors.fname = "*Please enter alphabet characters only.";
      }
    }
    if (!document.getElementById("lname").value) {
      formIsValid = false;
      errors.lname = "*Enter Last Name";
    }
    if (typeof document.getElementById("lname").value !== "undefined") {
      if (!document.getElementById("lname").value.match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors.lname = "*Please enter alphabet characters only.";
      }
    }

    if (!document.getElementById("timeslot").value) {
      formIsValid = false;
      errors["timeslot"] = "*Please select the slot";
    }

    this.setState(
      () => ({
        errors: errors,
      }),
      console.log(this.state.errors)
    );

    return formIsValid;
  }

  componentDidMount() {
    let tokenkey = JSON.parse(JSON.stringify(userContext.token));
    let token = "Bearer " + tokenkey.token;
    console.log("token::----*****)", token);
    fetch(API_URL_STUDENTSLIST, {
      method: "GET",
      headers: {
        authorization: token,
        Accept: "application/json",
        "Access-Control-Allow-Origin": {
          "http://localhost:3000": "https://www.kanaaksquare.com:3000",
        },
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((result) => {
            let feedata = JSON.parse(JSON.stringify(result));
            console.log("ANSWER::--> ", JSON.parse(JSON.stringify(result)));
            console.log("studentDetails::--> ", feedata);
            this.setState(
              {
                dataarray: feedata,
              },
              console.log(this.state.dataarray)
            );
          });
          console.log("Value of data" + this.data);
        } else {
          throw new Error(response.code);
        }
      })
      .catch((error) => {
        console.log("Data Error:" + error);
      });

    fetch(API_URL_COURSELIST, {
      method: "GET",
      headers: {
        authorization: token,
        Accept: "application/json",
        "Access-Control-Allow-Origin": {
          "http://localhost:3000": "https://www.kanaaksquare.com:3000",
        },
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((result) => {
            let feedata = JSON.parse(JSON.stringify(result));

            this.setState(
              {
                coursearray: feedata,
              },
              console.log(this.state.coursearray)
            );
          });
          console.log("Value of data" + this.data);
        } else {
          throw new Error(response.code);
        }
      })
      .catch((error) => {
        console.log("Data Error:" + error);
      });
  }

  render() {
    var curr = new Date();
    curr.setDate(curr.getDate() + 3);
    var date = curr.toISOString().substr(0, 10);
    return (
      <div className="content">
        <Row>
          <Col md="8">
            <Card className="card-user">
              <CardHeader>
                <CardTitle tag="h5">Course Enrollment</CardTitle>
              </CardHeader>
              <CardBody>
                <Alert
                  color="success"
                  id="success"
                  style={{ "background-color": "#ffffff" }}
                >
                  {this.state.resmsg.success}
                </Alert>
                <Alert
                  color="danger"
                  id="failure"
                  style={{ "background-color": "#ffffff" }}
                >
                  {this.state.resmsg.error}
                </Alert>

                <Form
                  name="registerform"
                  id="registerform"
                  encType="multipart/form-data"
                >
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <label>Registration Number</label>
                        <Input
                          defaultValue=""
                          placeholder="Reg No"
                          type="select"
                          id="studentid"
                          name="studentid"
                          onChange={this.handleChange}
                          requiredText="Select Reg No"
                          required
                        >
                          {this.state.dataarray.map((item, key) => (
                            <option value={item.studentid}>
                              {item.studentid} - {item.fname} {item.lname}
                            </option>
                          ))}
                        </Input>
                        <div className="text-danger">
                          {this.state.errors.fname}
                        </div>
                      </FormGroup>
                    </Col>
                    {/* <Col md="6">
                      <FormGroup>
                        <label>Name</label>
                        <Input
                          defaultValue=""
                          placeholder="Name"
                          type="select"
                          name="name"
                          id="name"
                          onChange={this.handleChange}
                        >
                          {this.state.dataarray.map((item, key) => (
                            <option>
                              {item.fname} {item.lname}
                            </option>
                          ))}
                        </Input>
                        <div className="text-danger">
                          {this.state.errors.lname}
                        </div>
                      </FormGroup>
                    </Col> */}
                  </Row>

                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label for="doj">Date of Joining</Label>
                        <Input
                          type="date"
                          name="enrolledon"
                          id="enrolledon"
                          defaultValue={date}
                          value={this.state.doj}
                          placeholder="date placeholder"
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6">
                      <Label for="timeslot">Time Slot</Label>
                      <Input
                        type="select"
                        name="timeslot"
                        id="timeslot"
                        onChange={this.handleChange}
                      >
                        <option>7:00-8:00 AM</option>
                        <option>7:00-8:00 AM</option>
                        <option>8:00-9:00 AM</option>
                        <option>9:00-10:00 AM</option>
                        <option>10:00-11:00 AM</option>
                        <option>11:00-12:00 AM</option>
                        <option>12:00-1:00 PM</option>
                        <option>1:00-2:00 PM</option>
                        <option>3:00-4:00 PM</option>
                        <option>4:00-5:00 PM</option>
                        <option>5:00-6:00 PM</option>
                        <option>6:00-7:00 PM</option>
                        <option>7:00-8:00 PM</option>
                      </Input>
                      <div className="text-danger">
                        {this.state.errors.timeslot}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label for="course">Course</Label>
                        <Input
                          type="select"
                          name="courseid"
                          id="courseid"
                          onChange={this.handleChange}
                        >
                          {/* <option>Core Python</option>
                          <option>Django</option>
                          <option>Core Java</option>
                          <option>Spring</option>
                          <option>Spring Boot</option>
                          <option>Lower-EN</option>
                          <option>Higher-EN</option>
                          <option>Lower-TA</option>
                          <option>Higher-TA</option>
                          <option>Shorthand</option>
                          <option>COA</option> */}
                          {this.state.coursearray.map((item, key) => (
                            <option value={item.courseid}>
                              {item.coursename}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <div className="update ml-auto mr-auto">
                      <Button
                        className="btn-round"
                        color="primary"
                        type="submit"
                        onClick={this.handleSubmit}
                      >
                        Enroll
                      </Button>
                    </div>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    ); //Return
  } //Render
} //Class
export default EnrollCourse;
